import { combineReducers } from 'redux'

import years from 'state/vehicleAttributes/years/reducers'
import makes from 'state/vehicleAttributes/makes/reducers'
import models from 'state/vehicleAttributes/models/reducers'
import submodels from 'state/vehicleAttributes/submodels/reducers'

export { genericInitialState as baseInitialState } from 'state/types'

export default combineReducers({
  years,
  makes,
  models,
  submodels,
})
