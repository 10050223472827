import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_ACCIDENT_DESCRIPTION_OPTIONS_BEGIN = 'attributes/CACHE_ACCIDENT_DESCRIPTION_OPTIONS_BEGIN',
  CACHE_ACCIDENT_DESCRIPTION_OPTIONS_SUCCESS = 'attributes/CACHE_ACCIDENT_DESCRIPTION_OPTIONS_SUCCESS',
  CACHE_ACCIDENT_DESCRIPTION_OPTIONS_FAILURE = 'attributes/CACHE_ACCIDENT_DESCRIPTION_OPTIONS_FAILURE',
}

export interface AccidentDescriptionOptionsState extends Omit<GenericState, 'data'> {
  data?: AccidentDescriptionOptionsByType,
}

export interface AccidentDescriptionOptionsByType {
  accident: AccidentDescriptionOption[],
  loss: AccidentDescriptionOption[],
  violation: AccidentDescriptionOption[],
}

export interface AccidentDescriptionOption {
  key: string,
  value: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    accidentDescriptionOptions: AccidentDescriptionOptionsByType,
    error?: ApiResponseData,
  },
}
