import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_ROOF_BEGIN = 'attributes/CACHE_ROOF_BEGIN',
  CACHE_ROOF_SUCCESS = 'attributes/CACHE_ROOF_SUCCESS',
  CACHE_ROOF_FAILURE = 'attributes/CACHE_ROOF_FAILURE',
}

export type RoofApiData = string[]

export interface RoofTypesState extends GenericState {
  data?: RoofTypes,
}

export type RoofTypes = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    roofTypes: RoofTypes,
    error?: ApiResponseData,
  },
}
