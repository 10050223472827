import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_HOME_REASONS_FOR_NO_PRIOR_INSURANCE_BEGIN = 'attributes/CACHE_HOME_REASONS_FOR_NO_PRIOR_INSURANCE_BEGIN',
  CACHE_HOME_REASONS_FOR_NO_PRIOR_INSURANCE_SUCCESS = 'attributes/CACHE_HOME_REASONS_FOR_NO_PRIOR_INSURANCE_SUCCESS',
  CACHE_HOME_REASONS_FOR_NO_PRIOR_INSURANCE_FAILURE = 'attributes/CACHE_HOME_REASONS_FOR_NO_PRIOR_INSURANCE_FAILURE',
}

export interface HomeReasonsForNoPriorInsuranceState extends GenericState {
  data?: HomeReasonsForNoPriorInsurance,
}

export interface HomeReasonsForNoPriorInsurance {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    reasonsForNoPriorInsurance: HomeReasonsForNoPriorInsurance,
    error?: ApiResponseData,
  },
}
