export default [
  'applicant.personalInfo.name.firstName',
  'applicant.personalInfo.name.lastName',
  'applicant.address.email',
  'applicant.address.phone.phoneNumber',
  'applicant.personalInfo.DOB',
  'applicant.personalInfo.gender',
  'applicant.personalInfo.industryId',
  'applicant.personalInfo.occupationId',
  'applicant.yearsAtResidence',
  'applicant.yearsAtPreviousAddress',
  'pplicant.previousAddress',
  'home.numChildren',
  'home.policyInfo.effective',
  'home.priorPolicyInfo.expiration',
  'home.priorPolicyInfo.priorCarrier',
  'home.priorPolicyInfo.yearsWithContinuousCoverage',
  'home.priorPolicyInfo.yearsWithPriorCarrier',
  'home.priorPolicyInfo.propertyInsCancelledLapsed',
  'applicant.personalInfo.maritalStatus',
]
