import { combineReducers } from 'redux'

import accidentDescriptionOptions from 'state/attributes/accidentDescriptionOptions/reducers'
import autoOwnerships from 'state/attributes/autoOwnerships/reducers'
import educations from 'state/attributes/educations/reducers'
import genders from 'state/attributes/genders/reducers'
import maritalStatuses from 'state/attributes/maritalStatuses/reducers'
import priorLiabilityLimits from 'state/attributes/priorLiabilityLimits/reducers'
import reasonsForLapse from 'state/attributes/reasonsForLapse/reducers'
import relations from 'state/attributes/relations/reducers'
import states from 'state/attributes/states/reducers'
import industries from 'state/attributes/industries/reducers'
import auto from 'state/attributes/auto/reducers'
import home from 'state/attributes/home/reducers'

export default combineReducers({
  auto,
  home,
  accidentDescriptionOptions,
  autoOwnerships,
  educations,
  genders,
  maritalStatuses,
  priorLiabilityLimits,
  reasonsForLapse,
  relations,
  states,
  industries,
})
