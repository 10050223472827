import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_AUTO_PRIOR_CARRIERS_BEGIN = 'attributes/CACHE_AUTO_PRIOR_CARRIERS_BEGIN',
  CACHE_AUTO_PRIOR_CARRIERS_SUCCESS = 'attributes/CACHE_AUTO_PRIOR_CARRIERS_SUCCESS',
  CACHE_AUTO_PRIOR_CARRIERS_FAILURE = 'attributes/CACHE_AUTO_PRIOR_CARRIERS_FAILURE',
}

export type AutoPriorCarriersApiData = string[]

export interface AutoPriorCarriersState extends GenericState {
  data?: AutoPriorCarriers,
}

export type AutoPriorCarriers = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    priorCarriers: AutoPriorCarriers,
    error?: ApiResponseData,
  },
}
