const deployment = process.env.RAZZLE_DEPLOYMENT as string

export default deployment

// Should the current deployment act like the production deployment?
export const isProductiony = [
  'production',
  'staging',
  'qa',
].includes(deployment)
