import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_AUTO_OWNERSHIPS_BEGIN = 'attributes/CACHE_AUTO_OWNERSHIPS_BEGIN',
  CACHE_AUTO_OWNERSHIPS_SUCCESS = 'attributes/CACHE_AUTO_OWNERSHIPS_SUCCESS',
  CACHE_AUTO_OWNERSHIPS_FAILURE = 'attributes/CACHE_AUTO_OWNERSHIPS_FAILURE',
}

export type AutoOwnershipsApiData = string[]

export interface AutoOwnershipsState extends GenericState {
  data?: AutoOwnerships,
}

export type AutoOwnerships = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    autoOwnerships: AutoOwnerships,
    error?: ApiResponseData,
  },
}
