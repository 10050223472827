import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  VehicleYearsState,
  CacheVehicleYearsBeginAction,
  CacheVehicleYearsSuccessAction,
  CacheVehicleYearsFailureAction,
} from 'state/vehicleAttributes/years/types'

const {
  CACHE_ALL_YEARS_BEGIN,
  CACHE_ALL_YEARS_SUCCESS,
  CACHE_ALL_YEARS_FAILURE,
} = ActionTypes

type Action = CacheVehicleYearsBeginAction |
  CacheVehicleYearsSuccessAction |
  CacheVehicleYearsFailureAction

export default (state: VehicleYearsState = deepClone(genericInitialState), action: Action): VehicleYearsState => {
  switch (action.type) {
    case CACHE_ALL_YEARS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_ALL_YEARS_SUCCESS:
      return {
        data: action.payload.years,
        cached: true,
        loading: false,
      }

    case CACHE_ALL_YEARS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
