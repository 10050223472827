import { CookieOptions } from './types'

const Cookie = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (name: string): any => {
    const c = document.cookie.match(`(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`)

    const decodedCookie = (c && decodeURIComponent(c[1]))

    return (decodedCookie !== 'undefined' && decodedCookie) || undefined
  },

  set: (name: string, value: string, opts: CookieOptions = {}): void => {
    const defaultDays = 30

    opts = {
      ...(opts || {}),
      path: opts.path || '/',
    }

    if (opts.days) {
      opts['max-age'] = Cookie.daysToMaxAge(opts.days)

      delete opts.days
    }
    else {
      opts['max-age'] = opts['max-age'] || Cookie.daysToMaxAge(defaultDays)
    }

    const stringOpts = Object.entries(opts).reduce((str, [
      k,
      v,
    ]) => `${str}; ${k}=${v}`, '')

    const cookie = `${name}=${encodeURIComponent(value)}${stringOpts}`

    document.cookie = cookie
  },

  daysToMaxAge: (days: number): number => days * 60 * 60 * 24,

  delete: (name: string, opts: CookieOptions): void => Cookie.set(name, '', {
    'max-age': -1,
    ...opts,
  }),
}

export default Cookie
