import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import sha1 from 'sha1'

import { apiBaseUrl } from 'config'

import isDebug from 'utils/isDebug'
import isServer from 'utils/isServer'

import {
  ApiResponse,
  Options,
} from './types'

export * from './types'

const defaultTimeout = 5000

export const get = (url: string, options?: Options): Promise<ApiResponse> => {
  return fetch(url, 'GET', options)
}

export const post = (url: string, data?: object, options?: Options): Promise<ApiResponse> => {
  return fetch(url, 'POST', options, data)
}

export const patch = (url: string, data?: object, options?: Options): Promise<ApiResponse> => {
  return fetch(url, 'PATCH', options, data)
}

const fetch = (url: string, method: string, options?: Options, data?: object): Promise<ApiResponse> => {
  const {
    generateHash: generateHash,
    headers: headersProp,
    timeout: timeoutProp,
  } = resolveOptions(options)

  const body = (data && JSON.stringify(data)) || undefined
  const headers = prepareHeaders(headersProp, generateHash)
  const request = {
    baseURL: apiBaseUrl,
    url: prepUrl(url),
    method: method,
    headers,
    timeout: timeoutProp || defaultTimeout,
  } as AxiosRequestConfig

  if (body) {
    request.data = body
  }

  const response = axios
    .request(request)
    .then(parseResponse)
    .then(resp => {
      if (isDebug && !isServer) {
        console.info(`[DEBUG] INL API Response to ${method} ${url}:`, resp) // eslint-disable-line no-console
      }

      return resp
    })
    .catch((err: AxiosError): ApiResponse => {
      let resp
      if (err.response?.status) {
        resp = err.response as AxiosResponse
      }
      else {
        // This is a network error
        resp = {
          status: 0, // Arbitrary "network-error" status code
          data: {
            errors: { 'network-error': [ err.message ] },
            message: `INL API Network Error (${method} ${apiBaseUrl}${url}).`,
            baseURL: apiBaseUrl,
            url: prepUrl(url),
            method: method,
            headers,
          },
        } as AxiosResponse
      }

      resp = parseResponse(resp)

      if (isDebug && !isServer) {
        console.error(`[DEBUG] INL API Error for ${method} ${url}:`, resp) // eslint-disable-line no-console

        Object.entries(resp.data.errors || []).map(([
          fieldName,
          error,
        ], i) => {
          console.warn(`[DEBUG] Returned Error #${i + 1} (${fieldName}):`, error) // eslint-disable-line no-console
        })
      }

      return resp
    })

  return response
}

const resolveOptions = (options: {
  generateHash?: boolean,
  headers?: object,
  timeout?: number,
} | undefined): Options => ({
  generateHash: (options && options.generateHash),
  headers: (options && options.headers),
  timeout: (options && options.timeout),
})

const prepareHeaders = (obj?: object, generateHashProp?: boolean): object => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...obj,
    ...((generateHashProp && generateHash()) || {}),
  }

  return headers
}

const generateHash = (): object => {
  const timestamp = Math.floor(Date.now() / 1000)
  const hash = sha1(`${timestamp}:${process.env.RAZZLE_API_HASH_SALT}`)

  return {
    Timestamp: timestamp,
    Hash: hash,
  }
}

const prepUrl = (url: string): string => `/${url.replace(/^\//, '').replace(/\/$/, '')}`

const parseResponse = (response: AxiosResponse): ApiResponse => {
  let resp: ApiResponse

  if (response.status >= 200 && response.status < 300) {
    resp = {
      data: response.data,
      message: response.data.message || undefined,
      pagination: response.headers.pagination ? JSON.parse(response.headers.pagination) : undefined,
      status: response.status,
      statusText: response.statusText,
      success: true,
    }
  }
  else {
    resp = {
      data: response.data || {},
      message: response.data.message || undefined,
      status: response.data.status || response.status || undefined,
      statusText: response.statusText,
      success: false,
    }
  }

  resp.firstErrorMessage = (resp.data.errors && Object.values(resp.data.errors)?.[0]?.[0]) || undefined

  return resp
}
