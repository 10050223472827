import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_ALL_BEGIN = 'insurance-types/CACHE_ALL_BEGIN',
  CACHE_ALL_SUCCESS = 'insurance-types/CACHE_ALL_SUCCESS',
  CACHE_ALL_FAILURE = 'insurance-types/CACHE_ALL_FAILURE',
}

export interface InsuranceTypesAction {
  type: ActionTypes,
  payload: {
    insuranceTypes?: InsuranceType[],
    error?: ApiResponseData,
  },
}

export interface InsuranceTypesState extends GenericState {
  data?: InsuranceType[],
}

export interface InsuranceType {
  id: number,
  slug: string,
  name: string,
  order: number,
  states: InsuranceTypeState[],
}

export interface InsuranceTypeState {
  abbr: string,
  name: string,
  is_available_online: boolean,
}
