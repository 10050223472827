import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_PRIOR_LIABILITY_LIMITS_BEGIN = 'attributes/CACHE_PRIOR_LIABILITY_LIMITS_BEGIN',
  CACHE_PRIOR_LIABILITY_LIMITS_SUCCESS = 'attributes/CACHE_PRIOR_LIABILITY_LIMITS_SUCCESS',
  CACHE_PRIOR_LIABILITY_LIMITS_FAILURE = 'attributes/CACHE_PRIOR_LIABILITY_LIMITS_FAILURE',
}

export interface PriorLiabilityLimitsState extends GenericState {
  data?: PriorLiabilityLimits,
}

export interface PriorLiabilityLimits {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    priorLiabilityLimits: PriorLiabilityLimits,
    error?: ApiResponseData,
  },
}
