export const homeLookupMap = {
  'home.construction': 'RatingInfo.Construction',
  'home.foundation': 'RatingInfo.Foundation',
  'home.dwellingUse': 'RatingInfo.DwellingUse',
  'home.withinCityLimits': 'RatingInfo.WithinCityLimits',
  'home.distanceToFireStation': 'RatingInfo.DistanceToFireStation',
  'home.distanceToFireHydrant': 'RatingInfo.DistanceToFireHydrant',
  'home.dogOnPremises': 'RatingInfo.DogOnPremises',
  'home.swimmingPool': 'RatingInfo.SwimmingPool',
  'home.roof': 'RatingInfo.Roof',
  'home.roofingUpdateYear': 'RatingInfo.RoofingUpdateYear',
  'home.heatingType': 'RatingInfo.HeatingType',
  'home.secondaryHeatingSource': 'RatingInfo.SecondaryHeatingSource',
  'home.heatingUpdateYear': 'RatingInfo.HeatingUpdateYear',
  'home.plumbingUpdateYear': 'RatingInfo.PlumbingUpdateYear',
  'home.electricCircuitBreaker': 'RatingInfo.ElectricCircuitBreaker',
  'home.electricalUpdateYear': 'RatingInfo.ElectricalUpdateYear',
  'home.firstMortgagee': 'RatingInfo.FirstMortgagee',
}

export default Object.keys(homeLookupMap)
