import { GenericState } from 'state/types'

export enum ActionTypes {
  CACHE_MODELS_BEGIN = 'vehicle-attributes/CACHE_MODELS_BEGIN',
  CACHE_MODELS_SUCCESS = 'vehicle-attributes/CACHE_MODELS_SUCCESS',
  CACHE_MODELS_FAILURE = 'vehicle-attributes/CACHE_MODELS_FAILURE',
}

export interface VehicleModelsState {
  [key: number]: VehicleModelsForMakeForYear,
}

interface VehicleModelsForMakeForYear {
  [key: string]: VehicleModelsForMake,
}

export interface VehicleModelsForMake extends GenericState {
  data?: VehicleModels,
}

export interface VehicleModels { [key: string]: string }

export interface CacheVehicleModelsBeginAction {
  type: ActionTypes.CACHE_MODELS_BEGIN,
  payload: {
    year: string,
    make: string,
  },
}

export interface CacheVehicleModelsSuccessAction {
  type: ActionTypes.CACHE_MODELS_SUCCESS,
  payload: {
    year: string,
    make: string,
    models: VehicleModelsState,
  },
}

export interface CacheVehicleModelsFailureAction {
  type: ActionTypes.CACHE_MODELS_FAILURE,
  payload: {
    year: string,
    make: string,
    error: Error,
  },
}
