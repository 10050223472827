import dottie from 'dottie'

import Window from 'types/window.d'

import isDebug from 'utils/isDebug'

import quoteRoutesInputsMap from 'components/App/Router/quoteRoutesInputsMap'

import { Value } from 'components/base/Form/Input'

import {
  get,
  post,
  patch,
  ApiResponse,
} from 'utils/apis/insuranceLounge'

import {
  CoverageLevel,
  CoverageLevelCoverageItem,
} from 'state/coverageLevels/types'
import { getCoverageLevelsForInsuranceTypeAndState } from 'state/coverageLevels/selectors'

import {
  GenericAction,
  RootState,
} from 'state/types'

import packageJson from '../../../package.json'

import {
  ActionTypes,
  ApiQuoteResponse,
  CarrierQuote,
  QuoteErrorInputName,
  QuoteCarrierResponseUpdatedEvent,
  QuoteCarrierResponseUpdatedEventData,
  QuotePaths,
  QuotesRequest,
  QuoteRequestEntries,
  QuoteResponseData,
  QuoteResponseErrors,
} from './types'

import {
  getQuoteRequest,
  getQuoteResponses,
} from './selectors'

declare let window: Window

const {
  CLEAR_CURRENT_REQUEST,
  SET_CURRENT_REQUEST,
  SET_CURRENT_REQUEST_ENTRIES,
  SET_CURRENT_RESPONSE_DATA,
  SET_CURRENT_RESPONSE_CARRIER_QUOTE_DATA,
  SET_CURRENT_RESPONSE_ENTRIES,
  SET_CURRENT_ERRORS,
  SET_SELECTED_CARRIER_RESPONSE_ID,
} = ActionTypes

export const clearCurrentQuoteRequest = (): GenericAction => ({ type: CLEAR_CURRENT_REQUEST })

export const setCurrentQuoteRequest = (request: QuotesRequest): Function => (dispatch: Function): void =>
  dispatch({
    type: SET_CURRENT_REQUEST,
    payload: { request },
  })

export const setCurrentQuoteRequestEntry = (key: string, value: Value): Function => (dispatch: Function): GenericAction | void => {
  if (typeof key !== 'string') {
    return
  }

  dispatch({
    type: SET_CURRENT_REQUEST_ENTRIES,
    payload: { entries: { [key]: value } },
  })
}

export const setCurrentQuoteRequestCoverageFromCoverageLevel = (insuranceType: string, stateAbbr: string, coverageLevelId: number): Function => (dispatch: Function, getState: Function): void => {
  const coverageLevels = getCoverageLevelsForInsuranceTypeAndState(getState(), insuranceType, stateAbbr)
  const selectedCoverageLevel = (coverageLevels.data && coverageLevels.data.find((coverageLevel: CoverageLevel) => coverageLevel.id === coverageLevelId)) || undefined

  const items = selectedCoverageLevel && selectedCoverageLevel.coverage_items.reduce((acc: {}, coverageItem: CoverageLevelCoverageItem) => {
    // Get value set by calculation or passed by API if it exists
    coverageItem.value = selectedCoverageLevel.categories.reduce((acc, category) => {
      const value = category.coverage_items.reduce((itemAcc, thisCoverageItem) => {
        if (thisCoverageItem.id === coverageItem.coverage_item_id) {
          itemAcc = thisCoverageItem.value
        }

        return itemAcc
      }, undefined as string|undefined)

      if (value !== undefined) {
        acc = value
      }

      return acc
    }, undefined as string|undefined)

    return {
      ...acc,
      [coverageItem.coverage_item_slug]: coverageItem.value || coverageItem.coverage_item_value_id,
    }
  }, {})

  dispatch(setCurrentQuoteRequestEntries({
    [`${insuranceType}.coverage.baseCoverageLevelId`]: coverageLevelId,
    [`${insuranceType}.coverage.isCustom`]: false,
    [`${insuranceType}.coverage.items`]: items,
  }))
}

export const deleteCurrentQuoteRequestEntry = (key: string): Function => (dispatch: Function): GenericAction | void => {
  if (typeof key !== 'string') {
    return
  }

  dispatch({
    type: SET_CURRENT_REQUEST_ENTRIES,
    payload: { entries: { [key]: undefined } },
  })
}

export const setCurrentQuoteRequestEntries = (entries: QuoteRequestEntries): Function => (dispatch: Function): GenericAction | void => {
  if (!(typeof entries === 'object' && !Array.isArray(entries))) {
    return
  }

  dispatch({
    type: SET_CURRENT_REQUEST_ENTRIES,
    payload: { entries },
  })
}

export const submitIncrementalQuoteRequest = (insuranceType: string): Function => async (dispatch: Function, getState: Function): Promise<void> => {
  const quoteResponses = getQuoteResponses(getState(), 'current')
  if (quoteResponses) {
    const { rateCallOne: rateCallOneResponse } = quoteResponses

    // Wipe reference to the existing/previous quote response if one exists
    if (rateCallOneResponse && rateCallOneResponse.data && rateCallOneResponse.data.id) {
      dispatch(stopCarrierQuoteEventListeners())
      await dispatch(clearCurrentQuoteResponseData('rateCallOne'))
      await dispatch(setCurrentQuoteRequestEntry('id', undefined))
    }
  }

  dispatch(submitCurrentQuoteRequest({
    insuranceType,
    isIncremental: true,
  }))
}

export const submitCurrentQuoteRequest = (options: {
  insuranceType: string,
  isIncremental?: boolean,
  isKickout?: boolean,
  overrideData?: object,
  timeout?: number,
}): Function => (dispatch: Function, getState: Function): Promise<ApiQuoteResponse> => {
  const { insuranceType } = options
  const isIncremental = (options && options.isIncremental) || false
  const isKickout = (options && options.isKickout) || false
  const overrideData = (options && options.overrideData) || {}

  const currentQuoteRequest = getQuoteRequest(getState(), 'current')
  const quoteRequestForPost = Object.assign({}, currentQuoteRequest)

  // Add self version number
  dottie.set(quoteRequestForPost, 'clientVersion', (packageJson && packageJson.version) || undefined)

  // Use override data
  if (overrideData && options.overrideData instanceof Object) {
    Object.keys(options.overrideData).map(key => {
      dottie.set(
        quoteRequestForPost,
        `applicant.address.${key}`,
        overrideData[key],
      )
    })
  }

  // Remove unnecessary data
  dottie.set(quoteRequestForPost, 'availableInsuranceTypes', null)

  // Sanitize phone number
  const phoneNumber: string = dottie.get(quoteRequestForPost, 'applicant.address.phone.phoneNumber')
  if (phoneNumber) {
    dottie.set(
      quoteRequestForPost,
      'applicant.address.phone.phoneNumber',
      phoneNumber.replace(/[^0-9]/g, ''),
    )
  }

  // Clear any previous errors
  dispatch(clearCurrentQuoteErrors('rateCallOne'))

  // Start loading state
  dispatch(setCurrentQuoteResponseStatus('rateCallOne', {
    error: undefined,
    errorMessage: undefined,
    loading: true,
  }))

  const url = (isIncremental && `/quotes/${insuranceType}`) || (isKickout && `/quotes/kickouts/${insuranceType}`) || `/quotes/rate-calls/${insuranceType}/1`

  return post(url, quoteRequestForPost, { timeout: options.timeout || 10000 })
    .then(resp => {
      return dispatch(handleInlApiQuoteResponse(resp))
    })
}

export const updateCurrentQuoteRequest = (id: string, quoteKey = 'rateCallOne'): Function => (dispatch: Function): Promise<ApiQuoteResponse> => {
  // Start loading state
  dispatch(setCurrentQuoteResponseStatus(quoteKey, {
    error: undefined,
    errorMessage: undefined,
    loading: true,
  }))
  dispatch(setCurrentQuoteErrors(quoteKey, {}))

  const url = `/quotes/${id}`

  return get(url, { timeout: 15000 }).then(resp => {
    return dispatch(handleInlApiQuoteResponse(resp, false))
  })
}

const handleInlApiQuoteResponse = (apiResponse: ApiResponse, clearDataOnError = true): Function => (dispatch: Function): ApiQuoteResponse | Promise<string> => {
  const resp = { ...apiResponse } as ApiQuoteResponse

  dispatch(setCurrentQuoteRequestEntry('id', (resp.data.quote && resp.data.quote.id) || undefined))
  dispatch(setCurrentQuoteRequestEntry('applicant.uuid', (resp.data.applicant && resp.data.applicant.uuid) || undefined))

  if (!resp.success) {
    const errorMessage = resp.data.message || 'Oops! We have a server error'

    clearDataOnError && dispatch(clearCurrentQuoteResponseData('rateCallOne'))
    dispatch(setCurrentQuoteResponseStatus('rateCallOne', {
      error: errorMessage,
      loading: false,
    }))
    dispatch(setCurrentQuoteErrors('rateCallOne', resp.data.errors as QuoteResponseErrors))

    return Promise.reject(errorMessage)
  }
  else {
    if (!resp.data.quote.is_incremental) {
      dispatch(setCurrentQuoteResponseData('rateCallOne', resp.data.quote))
      dispatch(setCurrentQuoteResponseStatus('rateCallOne', {
        error: undefined,
        loading: false,
      }))
      dispatch(setCurrentQuoteErrors('rateCallOne', {}))
    }
  }

  return resp
}

export const setCurrentQuoteResponseData = (
  responsePath: string,
  data: QuoteResponseData,
): Function => async (dispatch: Function): Promise<GenericAction> => {
  const ret = await dispatch({
    type: SET_CURRENT_RESPONSE_DATA,
    payload: {
      responsePath,
      data,
    },
  })

  dispatch(startCarrierQuoteEventListeners())

  return ret
}

export const startCarrierQuoteEventListeners = (): Function => (dispatch: Function, getState: Function): boolean => {
  const state: RootState = getState()
  const currentData: {
    id: string,
    carriers: CarrierQuote[],
  } = dottie.get(state, 'quotes.current.responses.rateCallOne.data')

  if (!(currentData && currentData.id && currentData.carriers)) {
    return false
  }

  // Subscribe to QuoteCarrierResponseUpdated events from API
  currentData.carriers.map((carrier: { broadcasted_on: string }) => {
    const channelUrl = carrier.broadcasted_on

    window.Echo.channel(channelUrl)
      .listen('QuoteCarrierResponseUpdated', (event: QuoteCarrierResponseUpdatedEvent) => {
        if (isDebug) {
          console.info('[DEBUG] QuoteCarrierResponseUpdatedEvent received', event) // eslint-disable-line no-console
        }

        dispatch(setCurrentQuoteResponseCarrierQuoteData('rateCallOne', event.carrierResponse))
      })
  })

  return true
}

export const prepareToSubmitNewQuote = (responsePath: QuotePaths): Function => async (dispatch: Function): Promise<void> => {
  await dispatch(stopCarrierQuoteEventListeners())
  await dispatch(clearCurrentQuoteResponseData(responsePath))
}

export const clearCurrentQuoteResponseData = (responsePath: string): Function => async (dispatch: Function): Promise<void> => {
  await dispatch({
    type: SET_CURRENT_RESPONSE_DATA,
    payload: {
      responsePath,
      data: undefined,
    },
  })
}

export const stopCarrierQuoteEventListeners = (): Function => (dispatch: Function, getState: Function): boolean => {
  const state: RootState = getState()
  const currentData: {
    id: string,
    carriers: CarrierQuote[],
  } = dottie.get(state, 'quotes.current.responses.rateCallOne.data')

  if (!(currentData && currentData.id && currentData.carriers)) {
    return false
  }

  // Unsubscribe from CarrierQuoteUpdated events from API
  currentData.carriers.map(carrierQuote => {
    const channelUrl = `quotes/${currentData.id}/carriers/${carrierQuote.id}`

    window.Echo.leave(channelUrl)
  })

  return true
}

export const setCurrentQuoteResponseCarrierQuoteData = (quoteResponsePath: string, data: QuoteCarrierResponseUpdatedEventData): Function => (dispatch: Function): GenericAction => {
  return dispatch({
    type: SET_CURRENT_RESPONSE_CARRIER_QUOTE_DATA,
    payload: {
      quoteResponsePath,
      data,
    },
  })
}

export const setCurrentQuoteResponseStatus = (responsePath: string, entries: QuoteRequestEntries): Function => (dispatch: Function): GenericAction | void => {
  if (!(typeof entries === 'object' && !Array.isArray(entries))) {
    return
  }

  // Disallow updating data via this method
  if (entries.data) {
    /* eslint-disable-next-line no-console */
    console.error('setCurrentQuoteResponseStatus is not allowed to update quote response data. Use setCurrentQuoteResponseData/clearCurrentQuoteResponseData instead.')

    delete entries.data
  }

  dispatch({
    type: SET_CURRENT_RESPONSE_ENTRIES,
    payload: {
      responsePath,
      entries,
    },
  })
}

export const setCurrentQuoteErrors = (errorsPath: string, errors: QuoteResponseErrors): Function => (dispatch: Function): GenericAction => {
  errors = transcribeReceivedErrorsToOtherInputs(errors)

  return dispatch({
    type: SET_CURRENT_ERRORS,
    payload: {
      errorsPath,
      errors,
    },
  })
}

const transcribeReceivedErrorsToOtherInputs = (errors: QuoteResponseErrors): QuoteResponseErrors => {
  // For any quoteInputs that specify displayErrorsOnQuoteRequestKeys, transcribe errors so that they show up on the target inputs
  for (const insuranceType in quoteRoutesInputsMap) {
    for (const screen in quoteRoutesInputsMap[insuranceType]) {
      for (const key in quoteRoutesInputsMap[insuranceType][screen]) {
        const input = quoteRoutesInputsMap[insuranceType][screen]?.[key]

        if (typeof input === 'object' && !(input instanceof RegExp)) {
          if (Object.keys(errors || {}).includes(input.quoteRequestKey)) {
            for (const quoteRequestKey of input.displayErrorsOnQuoteRequestKeys) {
              errors[quoteRequestKey] = errors[input.quoteRequestKey]
            }
          }
        }
      }
    }
  }

  return errors
}

export const clearCurrentQuoteError = (errorsPath: string, key: QuoteErrorInputName): Function => (dispatch: Function): GenericAction => dispatch(clearCurrentQuoteErrors(errorsPath, [ key ]))

export const clearCurrentQuoteErrors = (errorsPath: string, keys?: QuoteErrorInputName[]): Function => (dispatch: Function, getState: Function): GenericAction | void => {
  const { quotes: { current: { errors: { [errorsPath]: errors } = {} } = {} } = {} } = getState()

  if (!errors) {
    return
  }

  const newErrors = Object.entries(errors)
    .filter(([ existingKey ]) => {
      if (!keys) {
        return false
      }

      return !keys.find((passedKey: QuoteErrorInputName)  => {
        if (passedKey instanceof RegExp) {
          const matchResult = existingKey.match(passedKey)

          return Array.isArray(matchResult)
        }

        return existingKey === passedKey
      })
    })
    .reduce((errorsObject, [
      key,
      value,
    ]) => ({
      ...errorsObject,
      [key]: value,
    }), {})
  // if no errors are removed, no state change.
  if (Object.keys(newErrors).length === Object.keys(errors).length) {
    return
  }

  dispatch({
    type: SET_CURRENT_ERRORS,
    payload: {
      errorsPath,
      errors: newErrors,
    },
  })
}

export const setSelectedCarrierResponseId = (id: number, quoteKey = 'rateCallOne'): Function => (dispatch: Function, getState: Function): GenericAction => {
  const state: RootState = getState()
  const quoteId = dottie.get(state, 'quotes.current.responses.rateCallOne.data.id')

  const url = `/quotes/${quoteId}`
  // eslint-disable-next-line @typescript-eslint/camelcase
  patch(url, { selected_carrier_response_id: id })

  return dispatch({
    type: SET_SELECTED_CARRIER_RESPONSE_ID,
    payload: {
      id,
      quoteKey,
    },
  })
}
