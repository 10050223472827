import dottie from 'dottie'

import deepClone from 'utils/deepClone'

import {
  GenericState,
  genericInitialState,
} from 'state/types'

import {
  ActionTypes,
  VehicleSubmodelsState,
  CacheVehicleSubmodelsBeginAction,
  CacheVehicleSubmodelsSuccessAction,
  CacheVehicleSubmodelsFailureAction,
} from './types'

const {
  CACHE_SUBMODELS_BEGIN,
  CACHE_SUBMODELS_SUCCESS,
  CACHE_SUBMODELS_FAILURE,
} = ActionTypes

type Action = CacheVehicleSubmodelsBeginAction |
  CacheVehicleSubmodelsSuccessAction |
  CacheVehicleSubmodelsFailureAction

export default (state: VehicleSubmodelsState = deepClone(genericInitialState), action: Action): VehicleSubmodelsState => {
  switch (action.type) {
    case CACHE_SUBMODELS_BEGIN: {
      const make: GenericState = dottie.get(
        state,
        [
          'submodels',
          action.payload.year,
          action.payload.make,
        ],
      )

      return {
        ...state,
        [action.payload.year]: {
          ...(state[action.payload.year] || {}),
          [action.payload.make]: {
            ...(make || {}),
            [action.payload.model]: {
              ...genericInitialState,
              cached: false,
              loading: true,
            },
          },
        },
      }
    }

    case CACHE_SUBMODELS_SUCCESS: {
      const make: GenericState = dottie.get(
        state,
        [
          'submodels',
          action.payload.year,
          action.payload.make,
        ],
      )

      return {
        ...state,
        [action.payload.year]: {
          ...(state[action.payload.year] || {}),
          [action.payload.make]: {
            ...(make || {}),
            [action.payload.model]: {
              data: action.payload.submodels,
              cached: true,
              loading: false,
            },
          },
        },
      }
    }

    case CACHE_SUBMODELS_FAILURE: {
      const make: GenericState = dottie.get(
        state,
        [
          'submodels',
          action.payload.year,
          action.payload.make,
        ],
      )
      const model: GenericState = dottie.get(
        state,
        [
          'submodels',
          action.payload.year,
          action.payload.make,
        ],
      )

      return {
        ...state,
        [action.payload.year]: {
          ...(state[action.payload.year] || {}),
          [action.payload.make]: {
            ...(make || {}),
            [action.payload.model]: {
              ...((model && model.data) || {}),
              loading: false,
              error: action.payload.error,
            },
          },
        },
      }
    }

    default:
      return state
  }
}
