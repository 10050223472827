import {
  get,
  ApiResponse,
  ApiResponseData,
} from 'utils/apis/insuranceLounge'
import {
  CacheResponse,
  GenericAction,
} from 'state/types'

import {
  ActionTypes,
  InsuranceType,
} from './types'

const {
  CACHE_ALL_BEGIN,
  CACHE_ALL_SUCCESS,
  CACHE_ALL_FAILURE,
} = ActionTypes

const fetchAllInsuranceTypes = async (): Promise<InsuranceType[]> => {
  const insuranceTypes: ApiResponse = await get('/insurance-types')

  if (!insuranceTypes.success) {
    throw insuranceTypes
  }

  return insuranceTypes.data as InsuranceType[]
}

// eslint-disable-next-line import/prefer-default-export
export const cacheAllInsuranceTypes = () => async (dispatch: Function, getState: Function): CacheResponse => {
  if (getState().insuranceTypes.cached || getState().insuranceTypes.loading) {
    return true
  }

  dispatch(cacheAllInsuranceTypesBegin())

  return await fetchAllInsuranceTypes()
    .then((insuranceTypes: InsuranceType[]) => {
      dispatch(cacheAllInsuranceTypesSuccess(insuranceTypes))
    })
    .catch(error => {
      dispatch(cacheAllInsuranceTypesFailure(error))

      return Promise.reject(error.message || 'Unable to cache insurance types.')
    })
}

const cacheAllInsuranceTypesBegin = (): GenericAction => ({ type: CACHE_ALL_BEGIN })

const cacheAllInsuranceTypesSuccess = (insuranceTypes: InsuranceType[]): GenericAction => ({
  type: CACHE_ALL_SUCCESS,
  payload: { insuranceTypes },
})

const cacheAllInsuranceTypesFailure = (error: ApiResponseData): GenericAction => ({
  type: CACHE_ALL_FAILURE,
  payload: { error },
})
