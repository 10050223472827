import { createSelector } from 'reselect'
import dottie, { DottiePath } from 'dottie'

import quoteRoutesInputsMap from 'components/App/Router/quoteRoutesInputsMap'

import { getCoverageLevelsForInsuranceTypeAndState } from 'state/coverageLevels/selectors'

import { RootState } from 'state/types'

import {
  CarrierQuote,
  QuotesRequest,
  Quote,
  QuoteErrorInputName,
  QuotePaths,
  QuoteRequestCoverage,
  QuoteResponseErrors,
  QuotesResponses,
  ScreenWithErrors,
} from './types'

import { initialState } from './reducers'

const getQuote: (state: RootState, quoteKey?: string) => Quote | undefined = createSelector(
  [ (state: RootState, quoteKey = 'current'): Quote | undefined  => (quoteKey && state.quotes[quoteKey]) || undefined ],
  quote => quote,
)

export const getQuoteRequest: (state: RootState, quoteKey?: string) => QuotesRequest | undefined = createSelector(
  [
    (state: RootState, quoteKey = 'current'): QuotesRequest | undefined => {
      const quote = getQuote(state, quoteKey)

      return (quote && quote.request) || undefined
    },
  ],
  quoteRequest => quoteRequest,
)

export const getQuoteResponses: (state: RootState, quoteKey?: string) => QuotesResponses | undefined = createSelector(
  [
    (state: RootState, quoteKey = 'current'): QuotesResponses | undefined => {
      const quote = getQuote(state, quoteKey)

      return (quote && quote.responses) || undefined
    },
  ],
  quoteResponses => quoteResponses,
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQuoteResponseValue: (state: RootState, quoteKey?: string, responseKey?: string) => any = createSelector(
  [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: RootState, quoteKey: string | undefined = 'current', responseKey?: string): any => {
      if (!responseKey) {
        responseKey = quoteKey
        quoteKey = undefined
      }

      const quoteResponses = getQuoteResponses(state, quoteKey)

      return (quoteResponses && quoteResponses[responseKey]) || undefined
    },
  ],
  value => value,
)

export const getQuoteErrors: (state: RootState, quoteKey: string, rateCallKey: string) => QuoteResponseErrors = createSelector(
  [
    (state: RootState, quoteKey: string, rateCallKey: string): QuoteResponseErrors => {
      const quote = getQuote(state, quoteKey)

      const allErrors = (quote && quote.errors) || undefined

      return (allErrors && allErrors[rateCallKey]) || undefined
    },
  ],
  quoteErrors => quoteErrors,
)

export const getQuoteErrorValue: (state: RootState, rateCallKey: QuotePaths, quoteRequestField: string) => string = createSelector(
  [
    (state: RootState, rateCallKey: QuotePaths, quoteRequestField: string): string => {
      const quoteErrors = getQuoteErrors(state, 'current', rateCallKey) as object

      return (quoteErrors && quoteErrors[quoteRequestField]) || undefined
    },
  ],
  value => value,
)

export const getQuoteScreensWithErrors: (state: RootState, rateCallKey: string) => ScreenWithErrors[] = createSelector(
  [
    (state: RootState, rateCallKey: string): ScreenWithErrors[] => {
      const fieldErrors = getQuoteErrors(state, 'current', rateCallKey)
      if (!fieldErrors) {
        return []
      }

      const selectedInsuranceType = getCurrentSelectedInsuranceTypeSlug(state)
      if (!selectedInsuranceType) {
        return []
      }

      const flowInputsMap = quoteRoutesInputsMap[selectedInsuranceType]

      const allScreens = Object.entries(flowInputsMap).reduce((acc, [
        path,
        inputs,
      ]) => {
        acc.push({
          inputs,
          path,
        })

        return acc
      }, [] as ScreenWithErrors[])

      const screensWithErrors = allScreens.filter(route => Object.keys(fieldErrors).find((fieldName: string) => {
        if (!route.inputs) {
          return false
        }

        return route.inputs.find((inputName: QuoteErrorInputName): boolean => {
          if (inputName instanceof RegExp) {
            return (fieldName.match(inputName) && true) || false
          }

          return inputName === fieldName
        })
      }))

      return screensWithErrors
    },
  ],
  screensWithErrors => screensWithErrors,
)

export const isQuoteScreenInError: (state: RootState, responseKey: string, screenPath: string) => boolean = createSelector(
  [
    (state: RootState, responseKey: string, screenPath: string): boolean => {
      const screensWithErrors = getQuoteScreensWithErrors(state, responseKey)

      const isInError = (screensWithErrors.find((erredScreen: { path: string }) => {
        const regExp = `^${erredScreen.path.replace(/:[^/]*/g, '([^.]*)')}`

        return screenPath.match(regExp) === undefined
      }) && true) || false

      return isInError
    },
  ],
  isInError => isInError,
)

export const getCurrentSelectedInsuranceTypeSlug: (state: RootState) => string|undefined = createSelector(
  [
    (state: RootState): string|undefined => {
      let slug
      if (state.quotes.current.request?.selectedInsuranceTypes?.auto) {
        slug = 'auto'
      }
      else if (state.quotes.current.request?.selectedInsuranceTypes?.health) {
        slug = 'health'
      }
      else if (state.quotes.current.request?.selectedInsuranceTypes?.home) {
        slug = 'home'
      }
      else if (state.quotes.current.request?.selectedInsuranceTypes?.life) {
        slug = 'life'
      }
      else if (state.quotes.current.request?.selectedInsuranceTypes?.medicare) {
        slug = 'medicare'
      }

      return slug
    },
  ],
  selectedInsuranceTypeSlug => selectedInsuranceTypeSlug,
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQuoteRequestValue: (state: RootState, quoteKey: string, fieldKey?: DottiePath) => any = createSelector(
  [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: RootState, quoteKey: string, fieldKey?: DottiePath): any => {
      let newQuoteKey: string | undefined = quoteKey
      if (!fieldKey && quoteKey) {
        fieldKey = quoteKey
        newQuoteKey = undefined
      }

      const quoteRequest = getQuoteRequest(state, newQuoteKey)

      return (fieldKey && quoteRequest) ? dottie.get(quoteRequest, fieldKey, undefined) : undefined
    },
  ],
  value => value,
)

export const getQuoteRequestCoverage: (state: RootState, insuranceType: string, stateAbbr: string) => QuoteRequestCoverage = createSelector(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: RootState, insuranceType: string): any => getQuoteRequestValue(state, `${insuranceType}.coverage`),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: RootState, insuranceType: string, stateAbbr: string): any => getCoverageLevelsForInsuranceTypeAndState(state, insuranceType, stateAbbr),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (coverage: any, coverageLevels): QuoteRequestCoverage => {
    const selectedCoverageLevel = (coverageLevels.data && coverageLevels.data.find((coverageLevel: { id: number }) => coverageLevel.id === coverage.baseCoverageLevelId)) || undefined

    return {
      ...(selectedCoverageLevel || {}),
      items: coverage.items,
      baseCoverageLevelId: coverage.baseCoverageLevelId,
      isCustom: coverage.isCustom,
      isSameForAllVehicles: coverage.isSameForAllVehicles,
      vehicleCoverages: coverage.vehicleCoverages,
      cached: coverageLevels.cached,
      error: coverageLevels.error,
      loading: coverageLevels.loading,
    }
  },
)

export const isQuoteRequestPristine: (state: RootState) => boolean = createSelector(
  [
    (state: RootState): boolean => {
      const quote = (state.quotes.current && state.quotes.current.request) || undefined

      return JSON.stringify(quote) === JSON.stringify(initialState.current.request)
    },
  ],
  isPristine => isPristine,
)

export const getCarrierQuote: (state: RootState, quoteKey?: string | number, id?: number) => CarrierQuote | undefined = createSelector(
  [
    (state: RootState, quoteKey: string | number | undefined = 'current', id: number): CarrierQuote | undefined => {
      if (!id) {
        id = quoteKey as unknown as number
        quoteKey = undefined
      }

      const carrierQuotes = getQuoteResponseValue(state, quoteKey as string, 'rateCallOne.data.carriers') as (CarrierQuote[] | undefined)

      if (carrierQuotes) {
        const carrierQuote = carrierQuotes.find((carrierQuote: CarrierQuote) => {
          return carrierQuote.id === id
        })

        return carrierQuote || undefined
      }

      return undefined
    },
  ],
  selectedCarrierResponse => selectedCarrierResponse,
)

export const getSelectedCarrierResponse: (state: RootState) => CarrierQuote | undefined = createSelector(
  [
    (state: RootState): CarrierQuote | undefined => {
      const selectedCarrierResponseId = getQuoteResponseValue(state, 'rateCallOne.selectedCarrierResponseId') as number

      const carrierQuote = getCarrierQuote(state, selectedCarrierResponseId)

      return carrierQuote
    },
  ],
  selectedCarrierResponse => selectedCarrierResponse,
)
