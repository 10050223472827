export default [
  'applicant.personalInfo.name.firstName',
  'applicant.personalInfo.name.lastName',
  'applicant.address.email',
  {
    quoteRequestKey: 'applicant.address.city',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.county',
    displayErrorsOnQuoteRequestKeys: [ 'applicant.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.stateAbbr',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.streetName',
    displayErrorsOnQuoteRequestKeys: [ 'applicant.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.streetNumber',
    displayErrorsOnQuoteRequestKeys: [ 'applicant.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.zip5',
    displayErrorsOnQuoteRequestKeys: [ 'applicant.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.zip4',
    displayErrorsOnQuoteRequestKeys: [ 'applicant.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.zipCode',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  'applicant.address.phone.phoneNumber',
  'applicant.completeAddress',
  'auto.policyInfo.effective',
  'auto.priorPolicyInfo.expiration',
  'auto.priorPolicyInfo.priorCarrier',
  'auto.priorPolicyInfo.yearsWithContinuousCoverage',
  'auto.priorPolicyInfo.yearsWithPriorCarrier',
  'auto.priorPolicyInfo.reasonForLapse',
]
