import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  PriorLiabilityLimitsState,
} from 'state/attributes/priorLiabilityLimits/types'

const {
  CACHE_PRIOR_LIABILITY_LIMITS_BEGIN,
  CACHE_PRIOR_LIABILITY_LIMITS_SUCCESS,
  CACHE_PRIOR_LIABILITY_LIMITS_FAILURE,
} = ActionTypes

export default (state: PriorLiabilityLimitsState = deepClone(genericInitialState), action: AttributesAction): PriorLiabilityLimitsState => {
  switch (action.type) {
    case CACHE_PRIOR_LIABILITY_LIMITS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_PRIOR_LIABILITY_LIMITS_SUCCESS:
      return {
        data: action.payload.priorLiabilityLimits,
        cached: true,
        loading: false,
      }

    case CACHE_PRIOR_LIABILITY_LIMITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
