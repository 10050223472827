import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_HEATING_BEGIN = 'attributes/CACHE_HEATING_BEGIN',
  CACHE_HEATING_SUCCESS = 'attributes/CACHE_HEATING_SUCCESS',
  CACHE_HEATING_FAILURE = 'attributes/CACHE_HEATING_FAILURE',
}

export type HeatingApiData = string[]

export interface HeatingTypesState extends GenericState {
  data?: HeatingTypes,
}

export type HeatingTypes = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    heatingTypes: HeatingTypes,
    error?: ApiResponseData,
  },
}
