import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  StatesState,
} from 'state/attributes/states/types'

const {
  CACHE_STATES_BEGIN,
  CACHE_STATES_SUCCESS,
  CACHE_STATES_FAILURE,
} = ActionTypes

export default (state: StatesState = deepClone(genericInitialState), action: AttributesAction): StatesState => {
  switch (action.type) {
    case CACHE_STATES_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_STATES_SUCCESS:
      return {
        data: action.payload.states,
        cached: true,
        loading: false,
      }

    case CACHE_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
