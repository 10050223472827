import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_HYDRANT_DISTANCE_BEGIN = 'attributes/CACHE_HYDRANT_DISTANCE_BEGIN',
  CACHE_HYDRANT_DISTANCE_SUCCESS = 'attributes/CACHE_HYDRANT_DISTANCE_SUCCESS',
  CACHE_HYDRANT_DISTANCE_FAILURE = 'attributes/CACHE_HYDRANT_DISTANCE_FAILURE',
}

export type HydrantDistanceTypesApiData = string[]

export interface HydrantDistanceTypesState extends GenericState {
  data?: HydrantDistanceTypes,
}

export type HydrantDistanceTypes = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    hydrantDistanceTypes: HydrantDistanceTypes,
    error?: ApiResponseData,
  },
}
