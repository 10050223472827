import React from 'react'

import loadable from '@loadable/component'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import isDebug from 'utils/isDebug'

import { Props } from './types'

import {
  Push,
  Wrapper,
} from './styles'

const Http503 = loadable(() => import('../../screens/503').catch(() => () => null))

const DevUtils = loadable(() => import('../DevUtils').catch(() => () => null))
const Header = loadable(() => import('../Header').catch(() => () => null))
const Footer = loadable(() => import('../Footer').catch(() => () => null))

const ProNav = loadable(() => import('../blocks/ProNav').catch(() => () => null))

const Router = loadable(() => import('./Router').catch(() => () => null))

const ScrollToTop = loadable(() => import('./ScrollToTop').catch(() => () => null))

const SessionTimeout = loadable(() => import('./SessionTimeout').catch(() => () => null))

const App: React.FC<Props> = ({
  isMaintenanceMode,
  location: { pathname: url },
}): React.ReactElement => (
  <>
    {(url !== '/' && <ProNav />) || undefined}
    <ScrollToTop />
    <SessionTimeout />

    <Wrapper>
      {(isDebug && <DevUtils isOpen={false} />) || undefined}

      <ToastContainer
        position='top-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        draggable={true}
        pauseOnHover={true}
        progressClassName='inl-warning-progress-bar'
        toastClassName='inl-warning'
      />

      <Header />

      {(isMaintenanceMode && <Http503 />) || <Router />}

      <Push />
    </Wrapper>
    <Footer />
  </>
)

export default withRouter(App)
