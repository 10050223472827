export default [
  {
    quoteRequestKey: 'applicant.address.city',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.county',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.stateAbbr',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.streetName',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.streetNumber',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.zip5',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.zip4',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  {
    quoteRequestKey: 'applicant.address.zipCode',
    displayErrorsOnQuoteRequestKeys: [ 'home.completeAddress' ],
  },
  'home.completeAddress',
  'home.isDetailed',
]
