import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_MARITAL_STATUSES_BEGIN = 'attributes/CACHE_MARITAL_STATUSES_BEGIN',
  CACHE_MARITAL_STATUSES_SUCCESS = 'attributes/CACHE_MARITAL_STATUSES_SUCCESS',
  CACHE_MARITAL_STATUSES_FAILURE = 'attributes/CACHE_MARITAL_STATUSES_FAILURE',
}

export type MaritalStatusesApiData = string[]

export interface MaritalStatusesState extends GenericState {
  data?: MaritalStatuses,
}

export type MaritalStatuses = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    maritalStatuses: MaritalStatuses,
    error?: ApiResponseData,
  },
}
