import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  GarageTypesState,
} from 'state/attributes/home/garageTypes/types'

const {
  CACHE_GARAGE_BEGIN,
  CACHE_GARAGE_SUCCESS,
  CACHE_GARAGE_FAILURE,
} = ActionTypes

export default (state: GarageTypesState = deepClone(genericInitialState), action: AttributesAction): GarageTypesState => {
  switch (action.type) {

    case CACHE_GARAGE_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_GARAGE_SUCCESS:
      return {
        data: action.payload.garageTypes,
        cached: true,
        loading: false,
      }

    case CACHE_GARAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
