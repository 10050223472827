import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  UseageTypesState,
} from 'state/attributes/home/useageTypes/types'

const {
  CACHE_USEAGE_BEGIN,
  CACHE_USEAGE_SUCCESS,
  CACHE_USEAGE_FAILURE,
} = ActionTypes

export default (state: UseageTypesState = deepClone(genericInitialState), action: AttributesAction): UseageTypesState => {
  switch (action.type) {
    case CACHE_USEAGE_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_USEAGE_SUCCESS:
      return {
        data: action.payload.useageTypes,
        cached: true,
        loading: false,
      }

    case CACHE_USEAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
