import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_HOME_YEARS_AT_ADDRESS_BEGIN = 'attributes/CACHE_HOME_YEARS_AT_ADDRESS_BEGIN',
  CACHE_HOME_YEARS_AT_ADDRESS_SUCCESS = 'attributes/CACHE_HOME_YEARS_AT_ADDRESS_SUCCESS',
  CACHE_HOME_YEARS_AT_ADDRESS_FAILURE = 'attributes/CACHE_HOME_YEARS_AT_ADDRESS_FAILURE',
}

export type HomeYearsAtAddressTypesApiData = string[]

export interface HomeYearsAtAddressTypesState extends GenericState {
  data?: HomeYearsAtAddressTypes,
}

export type HomeYearsAtAddressTypes = string[]

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    yearsAtAddressTypes: HomeYearsAtAddressTypes,
    error?: ApiResponseData,
  },
}
