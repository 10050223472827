import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  DistanceToTidalWaterTypesState,
} from 'state/attributes/home/distanceToTidalWaterTypes/types'

const {
  CACHE_DISTANCE_TO_TIDAL_WATER_BEGIN,
  CACHE_DISTANCE_TO_TIDAL_WATER_SUCCESS,
  CACHE_DISTANCE_TO_TIDAL_WATER_FAILURE,
} = ActionTypes

export default (state: DistanceToTidalWaterTypesState = deepClone(genericInitialState), action: AttributesAction): DistanceToTidalWaterTypesState => {
  switch (action.type) {
    case CACHE_DISTANCE_TO_TIDAL_WATER_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_DISTANCE_TO_TIDAL_WATER_SUCCESS:
      return {
        data: action.payload.distanceToTidalWaterTypes,
        cached: true,
        loading: false,
      }

    case CACHE_DISTANCE_TO_TIDAL_WATER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
