import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_USEAGE_BEGIN = 'attributes/CACHE_USEAGE_BEGIN',
  CACHE_USEAGE_SUCCESS = 'attributes/CACHE_USEAGE_SUCCESS',
  CACHE_USEAGE_FAILURE = 'attributes/CACHE_USEAGE_FAILURE',
}

export type UseageApiData = string[]

export interface UseageTypesState extends GenericState {
  data?: UseageTypes,
}

export type UseageTypes = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    useageTypes: UseageTypes,
    error?: ApiResponseData,
  },
}
