import { ActionTypes } from './types'

const {
  SET_IS_MAINTENANCE_MODE,
  SET_IS_REDUX_LOGGER_ENABLED,
  SET_QUOTING_WRAPPER_BG_IMAGE_INDEX,
} = ActionTypes

export const setIsReduxLoggerEnabled = (isReduxLoggerEnabled: boolean): Function => (dispatch: Function): void => {
  dispatch({
    type: SET_IS_REDUX_LOGGER_ENABLED,
    payload: { isReduxLoggerEnabled },
  })
}

export const setQuotingWrapperBgImageIndex = (quotingWrapperBgImageIndex: number | undefined): Function => (dispatch: Function): void => {
  dispatch({
    type: SET_QUOTING_WRAPPER_BG_IMAGE_INDEX,
    payload: { quotingWrapperBgImageIndex },
  })
}

export const setIsMaintenanceMode = (isMaintenanceMode: boolean): Function => (dispatch: Function): void => {
  dispatch({
    type: SET_IS_MAINTENANCE_MODE,
    payload: { isMaintenanceMode },
  })
}
