import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_STATES_BEGIN = 'attributes/CACHE_STATES_BEGIN',
  CACHE_STATES_SUCCESS = 'attributes/CACHE_STATES_SUCCESS',
  CACHE_STATES_FAILURE = 'attributes/CACHE_STATES_FAILURE',
}

export interface StatesApiData {
  abbr: string,
  name: string,
}

export interface StatesState extends GenericState {
  data?: States,
}

export interface States {
  [abbr: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    states: States,
    error?: ApiResponseData,
  },
}
