import { createSelector } from 'reselect'

import { RootState } from 'state/types'

export const getIsReduxLoggerEnabled = createSelector(
  [ (state: RootState): boolean => state.app.isReduxLoggerEnabled || false ],
  isReduxLoggerEnabled => isReduxLoggerEnabled,
)

export const getQuotingWrapperBgImageIndex = createSelector(
  [ (state: RootState): number | undefined => state.app.quotingWrapperBgImageIndex || undefined ],
  quotingWrapperBgImageIndex => quotingWrapperBgImageIndex,
)

export const getIsMaintenanceMode = createSelector(
  [ (state: RootState): boolean => state.app.isMaintenanceMode || false ],
  isMaintenanceMode => isMaintenanceMode,
)
