import { v4 as uuid } from 'uuid'

import Cookie from 'lib/cookie'

export const start = (sessionId?: string|undefined): string => {
  sessionId = sessionId || getId() || uuid()

  Cookie.set('session-id', sessionId, { days: 7 })

  return sessionId
}

export const forget = (): void => {
  Cookie.delete('session-id', {})
}

export const getId = (): string | undefined => {
  const sessionId = Cookie.get('session-id') || undefined

  return sessionId
}
