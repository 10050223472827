import { combineReducers } from 'redux'

import constructionTypes from './constructionTypes/reducers'
import distanceToTidalWaterTypes from './distanceToTidalWaterTypes/reducers'
import foundationTypes from './foundationTypes/reducers'
import garageTypes from './garageTypes/reducers'
import heatingTypes from './heatingTypes/reducers'
import hydrantDistanceTypes from './hydrantDistanceTypes/reducers'
import priorCarriers from './priorCarriers/reducers'
import reasonsForNoPriorInsurance from './reasonsForNoPriorInsurance/reducers'
import roofTypes from './roofTypes/reducers'
import useageTypes from './useageTypes/reducers'
import yearsAtAddressTypes from './yearsAtAddressTypes/reducers'

export default combineReducers({
  constructionTypes,
  distanceToTidalWaterTypes,
  foundationTypes,
  garageTypes,
  heatingTypes,
  hydrantDistanceTypes,
  priorCarriers,
  roofTypes,
  useageTypes,
  reasonsForNoPriorInsurance,
  yearsAtAddressTypes,
})
