import { combineReducers } from 'redux'

import priorCarriers from './priorCarriers/reducers'

import reasonsForNoPriorInsurance from './reasonsForNoPriorInsurance/reducers'

export default combineReducers({
  priorCarriers,
  reasonsForNoPriorInsurance,
})
