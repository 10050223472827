import dottie from 'dottie'

import deepClone from 'utils/deepClone'

import {
  GenericState,
  genericInitialState,
} from 'state/types'

import {
  ActionTypes,
  VehicleModelsState,
  CacheVehicleModelsBeginAction,
  CacheVehicleModelsSuccessAction,
  CacheVehicleModelsFailureAction,
} from 'state/vehicleAttributes/models/types'

const {
  CACHE_MODELS_BEGIN,
  CACHE_MODELS_SUCCESS,
  CACHE_MODELS_FAILURE,
} = ActionTypes

type Action = CacheVehicleModelsBeginAction |
  CacheVehicleModelsSuccessAction |
  CacheVehicleModelsFailureAction

export default (state: VehicleModelsState = deepClone(genericInitialState), action: Action): VehicleModelsState => {
  switch (action.type) {
    case CACHE_MODELS_BEGIN:
      return {
        ...state,
        [action.payload.year]: {
          ...(state[action.payload.year] || {}),
          [action.payload.make]: {
            ...genericInitialState,
            cached: false,
            loading: true,
          },
        },
      }

    case CACHE_MODELS_SUCCESS:
      return {
        ...state,
        [action.payload.year]: {
          ...(state[action.payload.year] || {}),
          [action.payload.make]: {
            data: action.payload.models,
            cached: true,
            loading: false,
          },
        },
      }

    case CACHE_MODELS_FAILURE: {
      const make: GenericState = dottie.get(
        state,
        [
          'models',
          action.payload.year,
          action.payload.make,
        ],
      )

      return {
        ...state,
        [action.payload.year]: {
          ...(state[action.payload.year] || {}),
          [action.payload.make]: {
            ...((make && make.data) || {}),
            loading: false,
            error: action.payload.error,
          },
        },
      }
    }

    default:
      return state
  }
}
