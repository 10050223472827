import {
  createContext,
  createElement,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { clearCurrentQuoteRequest } from 'state/quotes/actions'

import { ContextProps } from './types'

const defaultIsOpen = false
const defaultIsSuccessOpen = false

export const ForgetMeContext = createContext<ContextProps>({
  isOpen: defaultIsOpen,
  setIsOpen: () => undefined,
  isSuccessOpen: defaultIsSuccessOpen,
  setIsSuccessOpen: () => undefined,
  resetSession: () => undefined,
})

export const ForgetMeContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const dispatch = useDispatch()

  const [
    isOpen,
    setIsOpen,
  ] = useState(defaultIsOpen)

  const [
    isSuccessOpen,
    setIsSuccessOpen,
  ] = useState(defaultIsSuccessOpen)

  const resetSession = (): void => {
    dispatch(clearCurrentQuoteRequest())

    setIsSuccessOpen(true)
  }

  return createElement(
    ForgetMeContext.Provider,
    {
      value: {
        isOpen,
        setIsOpen,
        isSuccessOpen,
        setIsSuccessOpen,
        resetSession,
      },
    },
    children,
  )
}
