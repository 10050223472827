
import quotingZipInputs from 'screens/Quoting/ZipCode/quoteInputs'

import quotingAutoDriversInputs from 'screens/Quoting/Auto/Drivers/quoteInputs'
import quotingAutoDriverInputs from 'screens/Quoting/Auto/Driver/quoteInputs'
import quotingAutoIncidentsInputs from 'screens/Quoting/Auto/Incidents/quoteInputs'
import quotingAutoIncidentInputs from 'screens/Quoting/Auto/Incident/quoteInputs'
import quotingAutoVehiclesInputs from 'screens/Quoting/Auto/Vehicles/quoteInputs'
import quotingAutoVehicleInputs from 'screens/Quoting/Auto/Vehicle/quoteInputs'
import quotingAutoPolicyInputs from 'screens/Quoting/Policy/quoteInputsAuto'

import quotingHomeAddressInputs from 'screens/Quoting/Home/Address/quoteInputs'
import quotingHomeAbout from 'screens/Quoting/Home/About/quoteInputs'
import quotingHomePolicyInputs from 'screens/Quoting/Policy/quoteInputsHome'

import { QuoteRoutesInputsMap } from './types'

const quoteRoutesInputsMap: QuoteRoutesInputsMap = {
  auto: {
    '/quote/new': undefined,
    '/quote/new/zip': quotingZipInputs,
    '/quote/new/insurance-types': undefined,
    '/quote/new/email': undefined,

    '/quote/new/auto': undefined,
    '/quote/new/auto/vehicles': quotingAutoVehiclesInputs,
    '/quote/new/auto/vehicle/:uuid?': quotingAutoVehicleInputs,
    '/quote/new/auto/drivers': quotingAutoDriversInputs,
    '/quote/new/auto/driver/:uuid?': quotingAutoDriverInputs,
    '/quote/new/auto/incidents': quotingAutoIncidentsInputs,
    '/quote/new/auto/incident/:uuid?': quotingAutoIncidentInputs,
    '/quote/new/auto/policy': quotingAutoPolicyInputs,
    '/quote/new/auto/coverage-levels': undefined,
    '/quote/new/auto/coverage-levels/:id/edit': undefined,
    '/quote/new/auto/rate-calls/1': undefined,
    '/quote/new/auto/rate-calls/1/success': undefined,
  },
  home: {
    '/quote/new': undefined,
    '/quote/new/zip': quotingZipInputs,
    '/quote/new/insurance-types': undefined,
    '/quote/new/email': undefined,

    '/quote/new/home': undefined,
    '/quote/new/home/address': quotingHomeAddressInputs,
    '/quote/new/home/about': quotingHomeAbout,
    '/quote/new/home/policy': quotingHomePolicyInputs,
    '/quote/new/home/coverage-levels': undefined,
    '/quote/new/home/coverage-levels/:id/edit': undefined,
    '/quote/new/home/rate-calls/1': undefined,
    '/quote/new/home/rate-calls/1/success': undefined,
  },
}

export default quoteRoutesInputsMap
