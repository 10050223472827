import {
  createContext,
  createElement,
  useState,
} from 'react'

import { ContextProps } from './types'

const defaultIsShowOnScrollEnabled = true
const defaultIsVisible = true
const defaultIsAtTop = true

export const HeaderContext = createContext<ContextProps>({
  isShowOnScrollEnabled: defaultIsShowOnScrollEnabled,
  setIsShowOnScrollEnabled: () => undefined,
  isVisible: defaultIsVisible,
  setIsVisible: () => undefined,
  isAtTop: defaultIsAtTop,
  setIsAtTop: () => undefined,
})

export const HeaderContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [
    isShowOnScrollEnabled,
    setIsShowOnScrollEnabled,
  ] = useState(defaultIsShowOnScrollEnabled)
  const [
    isVisible,
    setIsVisible,
  ] = useState(defaultIsVisible)
  const [
    isAtTop,
    setIsAtTop,
  ] = useState(defaultIsAtTop)
  const props = {
    value: {
      isShowOnScrollEnabled,
      setIsShowOnScrollEnabled,
      isVisible,
      setIsVisible,
      isAtTop,
      setIsAtTop,
    },
  }

  return createElement(
    HeaderContext.Provider,
    props,
    children,
  )
}
