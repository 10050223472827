import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  ConstructionTypesState,
} from 'state/attributes/home/constructionTypes/types'

const {
  CACHE_CONSTRUCTION_BEGIN,
  CACHE_CONSTRUCTION_SUCCESS,
  CACHE_CONSTRUCTION_FAILURE,
} = ActionTypes

export default (state: ConstructionTypesState = deepClone(genericInitialState), action: AttributesAction): ConstructionTypesState => {
  switch (action.type) {
    case CACHE_CONSTRUCTION_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_CONSTRUCTION_SUCCESS:
      return {
        data: action.payload.constructionTypes,
        cached: true,
        loading: false,
      }

    case CACHE_CONSTRUCTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
