import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  HydrantDistanceTypesState,
} from 'state/attributes/home/hydrantDistanceTypes/types'

const {
  CACHE_HYDRANT_DISTANCE_BEGIN,
  CACHE_HYDRANT_DISTANCE_SUCCESS,
  CACHE_HYDRANT_DISTANCE_FAILURE,
} = ActionTypes

export default (state: HydrantDistanceTypesState = deepClone(genericInitialState), action: AttributesAction): HydrantDistanceTypesState => {
  switch (action.type) {

    case CACHE_HYDRANT_DISTANCE_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_HYDRANT_DISTANCE_SUCCESS:
      return {
        data: action.payload.hydrantDistanceTypes,
        cached: true,
        loading: false,
      }

    case CACHE_HYDRANT_DISTANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
