import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_GENDERS_BEGIN = 'attributes/CACHE_GENDERS_BEGIN',
  CACHE_GENDERS_SUCCESS = 'attributes/CACHE_GENDERS_SUCCESS',
  CACHE_GENDERS_FAILURE = 'attributes/CACHE_GENDERS_FAILURE',
}

export type GendersApiData = string[]

export interface GendersState extends GenericState {
  data?: Genders,
}

export type Genders = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    genders: Genders,
    error?: ApiResponseData,
  },
}
