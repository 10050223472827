import { GenericState } from 'state/types'

export enum ActionTypes {
  CACHE_ALL_YEARS_BEGIN = 'vehicle-attributes/CACHE_ALL_YEARS_BEGIN',
  CACHE_ALL_YEARS_SUCCESS = 'vehicle-attributes/CACHE_ALL_YEARS_SUCCESS',
  CACHE_ALL_YEARS_FAILURE = 'vehicle-attributes/CACHE_ALL_YEARS_FAILURE',
}

export interface VehicleYearsState extends GenericState {
  data?: VehicleYears,
}

export interface VehicleYears { [key: number]: string }

export interface CacheVehicleYearsBeginAction {
  type: ActionTypes.CACHE_ALL_YEARS_BEGIN,
}

export interface CacheVehicleYearsSuccessAction {
  type: ActionTypes.CACHE_ALL_YEARS_SUCCESS,
  payload: {
    years: VehicleYears,
  },
}

export interface CacheVehicleYearsFailureAction {
  type: ActionTypes.CACHE_ALL_YEARS_FAILURE,
  payload: {
    error: Error,
  },
}
