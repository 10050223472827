import dottie from 'dottie'

import deepClone from 'utils/deepClone'

import packageJson from '../../../package.json'

import {
  QuotesState,
  ActionTypes,
  QuotesAction,
  QuotesRequest,
} from './types'

const {
  CLEAR_CURRENT_REQUEST,
  SET_CURRENT_REQUEST,
  SET_CURRENT_REQUEST_ENTRIES,
  SET_CURRENT_RESPONSE_DATA,
  SET_CURRENT_RESPONSE_CARRIER_QUOTE_DATA,
  SET_CURRENT_RESPONSE_ENTRIES,
  SET_CURRENT_ERRORS,
  SET_SELECTED_CARRIER_RESPONSE_ID,
} = ActionTypes

const initialBaseInsuranceType = {
  coverage: {
    baseCoverageLevelId: undefined,
    isCustom: false,
    items: {},
  },
}

export const initialState = {
  current: {
    request: {
      applicant: {
        address: { phone: { } },
        previousAddress: { },
        personalInfo: { name: { } },
      },
      auto: {
        ...initialBaseInsuranceType,
        coverage: {
          ...initialBaseInsuranceType.coverage,
          isSameForAllVehicles: true,
          vehicleCoverages: {},
        },
        drivers: {},
        incidents: {},
        vehicles: {},
      },
      clientPlatform: 'web',
      clientVersion: packageJson.version,
      home: {
        ...initialBaseInsuranceType,
        isDetailed: false,
      },
      id: undefined,
      isPublicComputer: false,
      other: { suggestInsurance: {} },
      selectedInsuranceTypes: {},
    },
    responses: {
      rateCallOne: {
        data: undefined,
        error: undefined,
        id: undefined,
        loading: true,
        selectedCarrierResponseId: undefined,
      },
    },
    errors: { rateCallOne: undefined },
  },
}

const cleanRequest = (request: QuotesRequest): QuotesRequest => {
  [
    (request.auto && request.auto.drivers) || undefined,
    (request.auto && request.auto.incidents) || undefined,
    (request.auto && request.auto.vehicles) || undefined,
  ].map(obj => {
    if (!obj) {
      return
    }

    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete obj[key]
      }
    })
  })

  return request
}

export default (state: QuotesState = deepClone(initialState), action: QuotesAction): QuotesState => {
  switch (action.type) {
    case CLEAR_CURRENT_REQUEST:
      return {
        ...state,
        current: deepClone(initialState.current),
      }

    case SET_CURRENT_REQUEST:
      return {
        ...state,
        current: {
          ...state.current,
          request: action.payload.request,
        },
      }

    case SET_CURRENT_REQUEST_ENTRIES: {
      const newRequest = {
        ...state.current.request,
        lastUpdatedAt: new Date(),
      }

      action.payload.entries && Object.entries(action.payload.entries).map(([
        key,
        value,
      ]) =>
        dottie.set(newRequest, key, value),
      )

      const cleanedNewRequest = cleanRequest(newRequest)

      return {
        ...state,
        current: {
          ...state.current,
          request: cleanedNewRequest,
        },
      }
    }

    case SET_CURRENT_RESPONSE_DATA: {
      const { responsePath } = action.payload
      const newResponses = {
        ...state.current.responses,
        [responsePath]: {
          ...(dottie.get(
            state.current.responses,
            responsePath,
          ) || {}) as {},
          data: action.payload.data,
          lastUpdatedAt: new Date(),
        },
      }

      return {
        ...state,
        current: {
          ...state.current,
          responses: newResponses,
        },
      }
    }

    case SET_CURRENT_RESPONSE_CARRIER_QUOTE_DATA: {
      if (!(action.payload.data && action.payload.data.id)) {
        // eslint-disable-next-line no-console
        console.error('Invalid carrier quote data received for SET_CURRENT_QUOTE_RESPONSE_CARRIER_QUOTE_DATA')

        return state
      }

      const responsePath: string = action.payload.quoteResponsePath

      const currentResponse = state.current.responses[responsePath]
      const currentCarrierQuotes = (currentResponse.data && currentResponse.data.carriers) || []

      const newCarrierQuotes = [ ...currentCarrierQuotes ]
      const carrierQuoteIndex = newCarrierQuotes.findIndex(item => !!action.payload.data && item.id === action.payload.data.id)

      if (carrierQuoteIndex !== -1) {
        newCarrierQuotes[carrierQuoteIndex] = action.payload.data
      }

      return {
        ...state,
        current: {
          ...state.current,
          responses: {
            ...state.current.responses,
            [action.payload.quoteResponsePath]: {
              ...(dottie.get(
                state.current.responses,
                action.payload.quoteResponsePath,
              ) || {}) as {},
              data: {
                ...state.current.responses[responsePath].data,
                // eslint-disable-next-line @typescript-eslint/camelcase
                carriers: newCarrierQuotes,
              },
            },
          },
        },
      }
    }

    case SET_CURRENT_RESPONSE_ENTRIES: {
      const newResponse = {
        ...(dottie.get(
          state.current.responses,
          action.payload.responsePath,
        ) || {}) as {},
        lastUpdatedAt: new Date(),
      }

      action.payload.entries && Object.entries(action.payload.entries).map(([
        key,
        value,
      ]) =>
        dottie.set(newResponse, key, value),
      )

      const newResponses = { ...state.current.responses }
      dottie.set(newResponses, action.payload.responsePath, newResponse)

      return {
        ...state,
        current: {
          ...state.current,
          responses: newResponses,
        },
      }
    }

    case SET_CURRENT_ERRORS: {
      const { errorsPath } = action.payload

      return {
        ...state,
        current: {
          ...state.current,
          errors: {
            ...state.current.errors,
            [errorsPath]: action.payload.errors,
          },
        },
      }
    }

    case SET_SELECTED_CARRIER_RESPONSE_ID: {
      const { quoteKey } = action.payload

      return {
        ...state,
        current: {
          ...state.current,
          responses: {
            ...state.current.responses,
            [quoteKey]: {
              ...state.current.responses[quoteKey],
              selectedCarrierResponseId: action.payload.id,
            },
          },
        },
      }
    }

    default:
      return state
  }
}
