import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100%;
  margin-bottom: -80px;
`

export const Push = styled.div`
  height: 80px;
`

export const Location = styled.a`
  :link,
  :hover,
  :active,
  :visited {
    color: #000000;
  }
`
