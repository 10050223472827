import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  RelationsState,
} from 'state/attributes/relations/types'

const {
  CACHE_RELATIONS_BEGIN,
  CACHE_RELATIONS_SUCCESS,
  CACHE_RELATIONS_FAILURE,
} = ActionTypes

export default (state: RelationsState = deepClone(genericInitialState), action: AttributesAction): RelationsState => {
  switch (action.type) {
    case CACHE_RELATIONS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_RELATIONS_SUCCESS:
      return {
        data: action.payload.relations,
        cached: true,
        loading: false,
      }

    case CACHE_RELATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
