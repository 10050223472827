import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  AutoOwnershipsState,
} from 'state/attributes/autoOwnerships/types'

const {
  CACHE_AUTO_OWNERSHIPS_BEGIN,
  CACHE_AUTO_OWNERSHIPS_SUCCESS,
  CACHE_AUTO_OWNERSHIPS_FAILURE,
} = ActionTypes

export default (state: AutoOwnershipsState = deepClone(genericInitialState), action: AttributesAction): AutoOwnershipsState => {
  switch (action.type) {
    case CACHE_AUTO_OWNERSHIPS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_AUTO_OWNERSHIPS_SUCCESS:
      return {
        data: action.payload.autoOwnerships,
        cached: true,
        loading: false,
      }

    case CACHE_AUTO_OWNERSHIPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
