import {
  createContext,
  createElement,
  useState,
} from 'react'

import { ContextProps } from './types'

const defaultIsOpen = false

export const MainNavContext = createContext<ContextProps>({
  isOpen: defaultIsOpen,
  setIsOpen: () => undefined,
})

export const MainNavContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(defaultIsOpen)

  const props: {
    value: ContextProps,
  } = {
    value: {
      isOpen,
      setIsOpen,
    },
  }

  return createElement(
    MainNavContext.Provider,
    props,
    children,
  )
}
