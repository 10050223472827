import dottie from 'dottie'

import deepClone from 'utils/deepClone'

import {
  GenericState,
  genericInitialState,
} from 'state/types'

import {
  ActionTypes,
  VehicleMakesState,
  CacheVehicleMakesBeginAction,
  CacheVehicleMakesSuccessAction,
  CacheVehicleMakesFailureAction,
} from 'state/vehicleAttributes/makes/types'

const {
  CACHE_MAKES_BEGIN,
  CACHE_MAKES_SUCCESS,
  CACHE_MAKES_FAILURE,
} = ActionTypes

type Action = CacheVehicleMakesBeginAction |
  CacheVehicleMakesSuccessAction |
  CacheVehicleMakesFailureAction

export default (state: VehicleMakesState = deepClone(genericInitialState), action: Action): VehicleMakesState => {
  switch (action.type) {
    case CACHE_MAKES_BEGIN:
      return {
        ...state,
        [action.payload.year]: {
          ...genericInitialState,
          cached: false,
          loading: true,
        },
      }

    case CACHE_MAKES_SUCCESS:
      return {
        ...state,
        [action.payload.year]: {
          data: action.payload.makes,
          cached: true,
          loading: false,
        },
      }

    case CACHE_MAKES_FAILURE: {
      const year: GenericState = dottie.get(state, `makes.${action.payload.year}`)

      return {
        ...state,
        [action.payload.year]: {
          ...((year && year.data) || {}),
          loading: false,
          error: action.payload.error,
        },
      }
    }

    default:
      return state
  }
}
