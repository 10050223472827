import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_HOME_PRIOR_CARRIERS_BEGIN = 'attributes/CACHE_HOME_PRIOR_CARRIERS_BEGIN',
  CACHE_HOME_PRIOR_CARRIERS_SUCCESS = 'attributes/CACHE_HOME_PRIOR_CARRIERS_SUCCESS',
  CACHE_HOME_PRIOR_CARRIERS_FAILURE = 'attributes/CACHE_HOME_PRIOR_CARRIERS_FAILURE',
}

export type HomePriorCarriersApiData = string[]

export interface HomePriorCarriersState extends GenericState {
  data?: HomePriorCarriers,
}

export type HomePriorCarriers = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    priorCarriers: HomePriorCarriers,
    error?: ApiResponseData,
  },
}
