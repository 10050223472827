import { GenericState } from 'state/types'

export enum ActionTypes {
  CACHE_SUBMODELS_BEGIN = 'vehicle-attributes/CACHE_SUBMODELS_BEGIN',
  CACHE_SUBMODELS_SUCCESS = 'vehicle-attributes/CACHE_SUBMODELS_SUCCESS',
  CACHE_SUBMODELS_FAILURE = 'vehicle-attributes/CACHE_SUBMODELS_FAILURE',
}

interface VehicleSubmodelsForModelForMakeForYear {
  [key: string]: VehicleSubmodelsForModelForMake,
}

interface VehicleSubmodelsForModelForMake {
  [key: string]: VehicleSubmodelsForModel,
}

export interface VehicleSubmodelsState {
  [key: number]: VehicleSubmodelsForModelForMakeForYear,
}

export interface VehicleSubmodelsForModel extends GenericState {
  data?: VehicleSubmodels,
}

export interface VehicleSubmodels {
  [key: string]: VehicleSubmodel,
}

export interface VehicleSubmodel {
  partialVin: string,
  submodel: string,
}

export interface CacheVehicleSubmodelsBeginAction {
  type: ActionTypes.CACHE_SUBMODELS_BEGIN,
  payload: {
    year: string,
    make: string,
    model: string,
  },
}

export interface CacheVehicleSubmodelsSuccessAction {
  type: ActionTypes.CACHE_SUBMODELS_SUCCESS,
  payload: {
    year: string,
    make: string,
    model: string,
    submodels: VehicleSubmodelsState,
  },
}

export interface CacheVehicleSubmodelsFailureAction {
  type: ActionTypes.CACHE_SUBMODELS_FAILURE,
  payload: {
    year: string,
    make: string,
    model: string,
    error: Error,
  },
}
