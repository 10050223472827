import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  EducationsState,
} from './types'

const {
  CACHE_EDUCATIONS_BEGIN,
  CACHE_EDUCATIONS_SUCCESS,
  CACHE_EDUCATIONS_FAILURE,
} = ActionTypes

export default (state: EducationsState = deepClone(genericInitialState), action: AttributesAction): EducationsState => {
  switch (action.type) {
    case CACHE_EDUCATIONS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_EDUCATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.educations,
        cached: true,
        loading: false,
      }

    case CACHE_EDUCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
