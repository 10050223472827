import {
  createContext,
  createElement,
  useState,
} from 'react'

import {
  ContextProps,
  ContextProviderProps,
} from './types'

const defaultIsEnabled = true

export const ScrollToTopContext = createContext<ContextProps>({
  isEnabled: defaultIsEnabled,
  setIsEnabled: () => undefined,
})

export const ScrollToTopContextProvider: React.FC<ContextProviderProps> = ({ children }): React.ReactElement => {
  const [
    isEnabled,
    setIsEnabled,
  ] = useState(defaultIsEnabled)

  return createElement(
    ScrollToTopContext.Provider,
    {
      value: {
        isEnabled,
        setIsEnabled,
      },
    },
    children,
  )
}
