import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  IndustriesState,
} from 'state/attributes/industries/types'

const {
  CACHE_INDUSTRIES_BEGIN,
  CACHE_INDUSTRIES_SUCCESS,
  CACHE_INDUSTRIES_FAILURE,
} = ActionTypes

export default (state: IndustriesState = deepClone(genericInitialState), action: AttributesAction): IndustriesState => {
  switch (action.type) {
    case CACHE_INDUSTRIES_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_INDUSTRIES_SUCCESS:
      return {
        data: action.payload.industries,
        cached: true,
        loading: false,
      }

    case CACHE_INDUSTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
