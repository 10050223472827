import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_CONSTRUCTION_BEGIN = 'attributes/CACHE_CONSTRUCTION_BEGIN',
  CACHE_CONSTRUCTION_SUCCESS = 'attributes/CACHE_CONSTRUCTION_SUCCESS',
  CACHE_CONSTRUCTION_FAILURE = 'attributes/CACHE_CONSTRUCTION_FAILURE',
}

export type ConstructionApiData = string[]

export interface ConstructionTypesState extends GenericState {
  data?: ConstructionTypes,
}

export type ConstructionTypes = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    constructionTypes: ConstructionTypes,
    error?: ApiResponseData,
  },
}
