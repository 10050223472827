import { ApiResponse } from 'utils/apis/insuranceLounge/types'

import { GenericAction } from 'state/types'

import {
  CoverageLevelCategory,
  CoverageLevelCoverageItem,
  CoverageLevelName,
} from 'state/coverageLevels/types'

import { InsuranceType } from 'state/insuranceTypes/types'

import { InputThatDisplaysErrorsOnOtherInputs } from 'components/App/Router/types'

export enum ActionTypes {
  CLEAR_CURRENT_REQUEST = 'quotes/CLEAR_CURRENT_REQUEST',
  SET_CURRENT_REQUEST = 'quotes/SET_CURRENT_REQUEST',
  SET_CURRENT_REQUEST_ENTRIES = 'quotes/SET_CURRENT_REQUEST_ENTRIES',
  SET_CURRENT_RESPONSE_DATA = 'quotes/SET_CURRENT_RESPONSE_DATA',
  SET_CURRENT_RESPONSE_CARRIER_QUOTE_DATA = 'quotes/SET_CURRENT_RESPONSE_CARRIER_QUOTE_DATA',
  SET_CURRENT_RESPONSE_ENTRIES = 'quotes/SET_CURRENT_RESPONSE_ENTRIES',
  SET_CURRENT_ERRORS = 'quotes/SET_CURRENT_ERRORS',
  SET_SELECTED_CARRIER_RESPONSE_ID = 'quotes/SET_SELECTED_CARRIER_RESPONSE_ID',
}

export interface QuotesState {
  current: Quote,
}

export interface Quote {
  request?: QuotesRequest,
  responses: QuotesResponses,
  errors: {
    [QuotePaths.RateCallOne]?: object,
  },
}

export interface QuotesRequest {
  applicant?: Applicant,
  auto?: AutoQuoteRequest,
  availableInsuranceTypes?: AvailableInsuranceType[],
  clientPlatform?: string,
  clientVersion?: string,
  home?: HomeQuoteRequest,
  id?: string,
  other?: { suggestInsurance?: SuggestInsurance },
  isPublicComputer?: boolean,
  residenceInfo?: {
    ownership?: string,
    ownershipType?: string,
    yearsAtCurrent?: {
      months: number,
      years: number,
    },
  },
  selectedInsuranceTypes?: {
    [insuranceType: string]: boolean,
  },
}

export interface Applicant {
  address: {
    email?: string,
    isLoading?: boolean,
    phone: { phoneNumber?: string },
    streetName?: string,
    streetNumber?: string,
    zip5?: string,
    zip4?: string,
    zipCode?: string,
  },
  completeAddress?: string,
  previousAddress?: object,
  personalInfo: {
    DOB?: string,
    education?: string,
    gender?: string,
    industryId?: string,
    maritalStatus?: string,
    name: {
      firstName?: string,
      lastName?: string,
    },
    occupationId?: number,
  },
  uuid?: string,
}

export interface AvailableInsuranceType extends InsuranceType {
  isAvailableOnline: boolean,
}

export interface SuggestInsurance {
  email?: string,
  comments?: string,
}

export interface QuoteRequest {
  coverage: QuoteRequestCoverage,
}

export interface QuoteRequestCoverage {
  baseCoverageLevelId?: number,
  cached?: boolean,
  categories?: CoverageLevelCategory[],
  coverage_items?: CoverageLevelCoverageItem[],
  isCustom: boolean,
  items: object,
  name?: CoverageLevelName,
  loading?: boolean,
}

interface AutoQuoteRequest extends QuoteRequest {
  coverage: AutoQuoteRequestCoverage,
  drivers: Drivers,
  incidents: Incidents,
  policyInfo?: object,
  priorPolicyInfo?: object,
  vehicles: Vehicles,
}

export interface AutoQuoteRequestCoverage extends QuoteRequestCoverage {
  isSameForAllVehicles: boolean,
  vehicleCoverages: object,
}

export interface Drivers { [uuid: string]: Driver }

export interface Driver {
  DOB: string,
  firstName: string,
  gender: string,
  id: string,
  lastName: string,
  maritalStatus: string,
  relation: string,
  principalVehicle: string,
}

export interface Incidents { [uuid: string]: Incident }

export interface Incident {
  date?: string,
  id: string,
  type?: IncidentType,
  value?: string,
}

export enum IncidentType {
  accident = 'accident',
  violation = 'violation',
  loss = 'loss',
}

export interface Vehicles {
  [uuid: string]: Vehicle,
}

export interface Vehicle {
  annualMiles?: string,
  id: string,
  make?: string,
  model?: string,
  ownership?: string,
  principalOperator?: string,
  submodel?: string,
  useage?: string,
  useageType?: string,
  useVinLookup?: boolean,
  vin?: string,
  year?: string,
  yearsOwned?: string,
}

interface HomeQuoteRequest extends QuoteRequest {
  yearBuilt?: number,
  replacementValue?: number,
  squareFootage?: number,
  numberOfStories?: number,
  purchaseDate?: string,

}

export interface HomeDetailedQuoteRequest extends HomeQuoteRequest {
  construction?: string,
  distanceToFireHydrant?: string,
  distanceToFireStation?: string,
  dogOnPremises?: string,
  dwellingUse?: string,
  electricalUpdateYear?: string,
  electricCircuitBreaker?: string,
  firstMortgagee?: string,
  foundation?: string,
  heatingType?: string,
  heatingUpdateYear?: string,
  plumbingUpdateYear?: string,
  residence?: string,
  roofingUpdateYear?: string,
  roof?: string,
  secondaryHeatingSource?: string,
  swimmingPool?: string,
  withinCityLimits?: string,
}


export interface QuoteRequestEntries {
  [key: string]: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface BaseQuoteResponse {
  id?: string,
  loading: boolean,
  selectedCarrierResponseId?: number,
}

export interface ApiQuoteResponse extends BaseQuoteResponse, Omit<ApiResponse, 'error'> {
  data: {
    applicant: { uuid: string },
    errors: QuoteResponseErrors,
    message: string,
    quote: QuoteResponseData,
  },
}

export interface QuotesResponses {
  [QuotePaths.RateCallOne]: QuoteResponse,
}

export interface QuoteResponse extends BaseQuoteResponse {
  data?: QuoteResponseData,
  error?: {
    data: {
      message: string,
      errors: QuoteResponseErrors,
    },
  },
}

export enum QuotePaths {
  RateCallOne = 'rateCallOne',
}

export interface QuoteResponseData {
  carrier_prompts: CarrierPrompt[],
  carriers: CarrierQuote[],
  created_at: string,
  id: string,
  is_incremental: false,
  is_kickout: false,
  response: {
    ApplicantID: number,
    QuoteDataResponse: object,
  },
  selected_carrier_response_id: null,
  service: string,
  status: string,
  updated_at: string,
}

export interface CarrierPrompt {
  CarrierID: string,
  PropertValues: {
    Name: string,
    _: string,
  }[],
}

export enum CarrierQuoteStatus {
  failed = 'failed',
  pending = 'pending',
  success = 'success',
  timeout = 'timeout',
}

export interface CarrierQuote {
  broadcasted_on: string,
  carrier: Carrier,
  created_at: string,
  id: number,
  monthly?: number,
  monthly_down_payment?: number,
  response: {
    Status: string,
    Carrier: {
      ID: number,
      LOB: string,
      Name: string,
    },
    QuoteExecutionID: string,
    StatusText: string,
  },
  semiyearly?: number,
  slug: string,
  status: CarrierQuoteStatus,
  updated_at: string,
  yearly?: number,
}

export interface QuoteCarrierResponseUpdatedEvent {
  carrierResponse: QuoteCarrierResponseUpdatedEventData,
}

export interface QuoteCarrierResponseUpdatedEventData {
  carrier: Carrier,
  created_at: string,
  id: number,
  monthly?: number,
  monthly_down_payment?: number,
  response: object,
  semiyearly?: number,
  status: CarrierQuoteStatus,
  updated_at: string,
  yearly?: number,
}

export interface Carrier {
  id: number,
  slug: string,
  ezlynx_id: string,
  name: string,
  updated_at: string,
}

export interface QuotesAction extends GenericAction {
  type: ActionTypes,
  payload: {
    id: number,
    quoteKey: string,
    data?: {
      id?: number,
    },
    request?: object,
    entries?: object,
    responsePath: string,
    quoteResponsePath: string,
    errorsPath: string,
    errors: QuoteResponseErrors,
  },
}

export interface QuoteResponseErrors {
  [key: string]: string[],
}

export interface ScreenWithErrors {
  inputs: QuoteErrorInputName[] | undefined,
  path: string,
}

export type QuoteErrorInputName = string | RegExp | InputThatDisplaysErrorsOnOtherInputs
