import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  AutoReasonsForNoPriorInsuranceState,
} from 'state/attributes/auto/reasonsForNoPriorInsurance/types'

const {
  CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_BEGIN,
  CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_SUCCESS,
  CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_FAILURE,
} = ActionTypes

export default (state: AutoReasonsForNoPriorInsuranceState = deepClone(genericInitialState), action: AttributesAction): AutoReasonsForNoPriorInsuranceState => {
  switch (action.type) {
    case CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_SUCCESS:
      return {
        data: action.payload.reasonsForNoPriorInsurance,
        cached: true,
        loading: false,
      }

    case CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
