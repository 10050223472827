import {
  Options,
  Scope,
} from '@sentry/types'


import isServer from 'utils/isServer'
import deployment from 'utils/deployment'

import packageJson from '../../../package.json'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (Sentry: any): void => {
  if (process.env.RAZZLE_SENTRY_ENABLED !== 'true') {
    return
  }

  const release = (packageJson && `${packageJson.name}@${packageJson.version}`) || undefined

  Sentry.init({
    dsn: process.env.RAZZLE_SENTRY_LARAVEL_DSN,
    environment: deployment,
    release,
  } as Options)
  Sentry.configureScope((scope: Scope) => {
    scope.setTag('environment.running_on', isServer ? 'server' : 'client')
  })
}
