import {
  ActionTypes,
  AppAction,
  AppState,
} from './types'

const {
  SET_IS_MAINTENANCE_MODE,
  SET_IS_REDUX_LOGGER_ENABLED,
  SET_QUOTING_WRAPPER_BG_IMAGE_INDEX,
} = ActionTypes

export const initialState: AppState = {
  isMaintenanceMode: false,
  isReduxLoggerEnabled: false,
  quotingWrapperBgImageIndex: undefined,
}

export default (state: AppState = initialState, action: AppAction): AppState => {
  switch (action.type) {
    case SET_IS_REDUX_LOGGER_ENABLED:
      return {
        ...state,
        isReduxLoggerEnabled: action.payload.isReduxLoggerEnabled,
      }

    case SET_QUOTING_WRAPPER_BG_IMAGE_INDEX:
      return {
        ...state,
        quotingWrapperBgImageIndex: action.payload.quotingWrapperBgImageIndex,
      }

    case SET_IS_MAINTENANCE_MODE:
      return {
        ...state,
        isMaintenanceMode: action.payload.isMaintenanceMode,
      }

    default:
      return state
  }
}
