import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  AccidentDescriptionOptionsState,
} from 'state/attributes/accidentDescriptionOptions/types'

const {
  CACHE_ACCIDENT_DESCRIPTION_OPTIONS_BEGIN,
  CACHE_ACCIDENT_DESCRIPTION_OPTIONS_SUCCESS,
  CACHE_ACCIDENT_DESCRIPTION_OPTIONS_FAILURE,
} = ActionTypes

export default (state: AccidentDescriptionOptionsState = deepClone(genericInitialState), action: AttributesAction): AccidentDescriptionOptionsState => {
  switch (action.type) {
    case CACHE_ACCIDENT_DESCRIPTION_OPTIONS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_ACCIDENT_DESCRIPTION_OPTIONS_SUCCESS:
      return {
        data: action.payload.accidentDescriptionOptions,
        cached: true,
        loading: false,
      }

    case CACHE_ACCIDENT_DESCRIPTION_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
