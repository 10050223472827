import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_BEGIN = 'coverage-levels/CACHE_BEGIN',
  CACHE_SUCCESS = 'coverage-levels/CACHE_SUCCESS',
  CACHE_FAILURE = 'coverage-levels/CACHE_FAILURE',
  SET_COVERAGE_LEVELS = 'coverage-levels/SET_COVERAGE_LEVELS',
}

export enum CoverageLevelName {
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
}

export interface CoverageLevel {
  bg_color: string,
  categories: CoverageLevelCategory[],
  coverage_items: CoverageLevelCoverageItem[],
  id: number,
  name: CoverageLevelName,
}

export interface CoverageLevelCoverageItem {
  coverage_item_id: number,
  coverage_item_slug: string,
  coverage_item_value_id: number,
  value?: string|undefined,
}

export interface CoverageLevelCategory {
  coverage_items: CoverageItem[],
  id: number,
  name: string,
  order: number,
  show_in_list: boolean,
  slug: string,
}

export interface CoverageItem {
  calculation?: string,
  id: number,
  input_type: string,
  slug: string,
  name: string,
  tooltip: string,
  ezlynx_key: string,
  show_in_list: boolean,
  show_in_details: boolean,
  value?: string,
  valueCalculatedBy?: string,
  values?: CoverageItemValue[],
}

export interface CoverageItemValue {
  id: number,
  ezlynx_value: string,
  name: string,
  show_in_details: boolean,
}

export interface CoverageLevelsByState {
  [stateAbbr: string]: CoverageLevel[],
}

export interface CoverageLevelsForInsuranceType extends GenericState {
  data?: CoverageLevelsByState,
}

export interface CoverageLevelsForInsuranceTypeAndState extends GenericState {
  data?: CoverageLevel[],
}

export interface CoverageLevelsState extends Omit<GenericState, 'data'|'cached'|'loading'> {
  auto?: CoverageLevelsByState,
  home?: CoverageLevelsByState,
  cached: Status,
  loading: Status,
}

export interface Status {
  insuranceTypes: {
    [insuranceType: string]: boolean,
  },
  insuranceTypeDotStates: {
    [insuranceTypeDotState: string]: boolean,
  },
}

export interface CoverageLevelsAction {
  type: ActionTypes,
  payload: {
    coverageLevels: CoverageLevel[],
    error?: string,
    insuranceType?: string,
    stateAbbr?: string,
    value?: string,
  },
}
