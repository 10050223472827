import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import {
  persistor,
  store,
} from './state/configureStore'

import App from './components/App'

const Root: React.FC = (): React.ReactElement =>
  React.createElement(
    Provider,
    { store },
    React.createElement(
      PersistGate,
      {
        loading: undefined,
        persistor,
      },
      React.createElement(App),
    ),
  )

export default Root
