import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_RELATIONS_BEGIN = 'attributes/CACHE_RELATIONS_BEGIN',
  CACHE_RELATIONS_SUCCESS = 'attributes/CACHE_RELATIONS_SUCCESS',
  CACHE_RELATIONS_FAILURE = 'attributes/CACHE_RELATIONS_FAILURE',
}

export interface RelationsState extends GenericState {
  data?: Relations,
}

export interface Relations {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    relations: Relations,
    error?: ApiResponseData,
  },
}
