import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_REASONS_FOR_LAPSE_BEGIN = 'attributes/CACHE_REASONS_FOR_LAPSE_BEGIN',
  CACHE_REASONS_FOR_LAPSE_SUCCESS = 'attributes/CACHE_REASONS_FOR_LAPSE_SUCCESS',
  CACHE_REASONS_FOR_LAPSE_FAILURE = 'attributes/CACHE_REASONS_FOR_LAPSE_FAILURE',
}

export interface ReasonsForLapseState extends GenericState {
  data?: ReasonsForLapse,
}

export interface ReasonsForLapse {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    reasonsForLapse: ReasonsForLapse,
    error?: ApiResponseData,
  },
}
