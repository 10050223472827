import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_EDUCATIONS_BEGIN = 'attributes/CACHE_EDUCATIONS_BEGIN',
  CACHE_EDUCATIONS_SUCCESS = 'attributes/CACHE_EDUCATIONS_SUCCESS',
  CACHE_EDUCATIONS_FAILURE = 'attributes/CACHE_EDUCATIONS_FAILURE',
}

export type EducationsApiData = string[]

export interface EducationsState extends GenericState {
  data?: Educations,
}

export type Educations = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    educations: Educations,
    error?: ApiResponseData,
  },
}
