import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  GendersState,
} from './types'

const {
  CACHE_GENDERS_BEGIN,
  CACHE_GENDERS_SUCCESS,
  CACHE_GENDERS_FAILURE,
} = ActionTypes

export default (state: GendersState = deepClone(genericInitialState), action: AttributesAction): GendersState => {
  switch (action.type) {

    case CACHE_GENDERS_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_GENDERS_SUCCESS:
      return {
        data: action.payload.genders,
        cached: true,
        loading: false,
      }

    case CACHE_GENDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
