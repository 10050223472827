import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_DISTANCE_TO_TIDAL_WATER_BEGIN = 'attributes/CACHE_DISTANCE_TO_TIDAL_WATER_BEGIN',
  CACHE_DISTANCE_TO_TIDAL_WATER_SUCCESS = 'attributes/CACHE_DISTANCE_TO_TIDAL_WATER_SUCCESS',
  CACHE_DISTANCE_TO_TIDAL_WATER_FAILURE = 'attributes/CACHE_DISTANCE_TO_TIDAL_WATER_FAILURE',
}

export type DistanceToTidalWaterApiData = string[]

export interface DistanceToTidalWaterTypesState extends GenericState {
  data?: DistanceToTidalWaterTypes,
}

export type DistanceToTidalWaterTypes = {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    distanceToTidalWaterTypes: DistanceToTidalWaterTypes,
    error?: ApiResponseData,
  },
}
