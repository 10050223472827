import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_BEGIN = 'attributes/CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_BEGIN',
  CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_SUCCESS = 'attributes/CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_SUCCESS',
  CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_FAILURE = 'attributes/CACHE_AUTO_REASONS_FOR_NO_PRIOR_INSURANCE_FAILURE',
}

export interface AutoReasonsForNoPriorInsuranceState extends GenericState {
  data?: AutoReasonsForNoPriorInsurance,
}

export interface AutoReasonsForNoPriorInsurance {
  [key: string]: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    reasonsForNoPriorInsurance: AutoReasonsForNoPriorInsurance,
    error?: ApiResponseData,
  },
}
