import { ApiResponseData } from 'utils/apis/insuranceLounge/types'

import { GenericState } from 'state/types'

export enum ActionTypes{
  CACHE_INDUSTRIES_BEGIN = 'attributes/CACHE_INDUSTRIES_BEGIN',
  CACHE_INDUSTRIES_SUCCESS = 'attributes/CACHE_INDUSTRIES_SUCCESS',
  CACHE_INDUSTRIES_FAILURE = 'attributes/CACHE_INDUSTRIES_FAILURE',
}

export interface IndustriesState extends GenericState {
  data?: Industries,
}

export interface Industries {
  [id: number]: Industry,
}

export interface Industry {
  ezlynx_code: string,
  id: number,
  name: string,
  occupations: Occupation[],
}

export interface Occupation {
  ezlynx_code: string,
  id: number,
  name: string,
}

export interface AttributesAction {
  type: ActionTypes,
  payload: {
    industries: Industries,
    error?: ApiResponseData,
  },
}
