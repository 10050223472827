import {
  ActionTypes,
  InsuranceTypesAction,
  InsuranceTypesState,
} from './types'

export const initialState: InsuranceTypesState = {
  data: undefined,
  cached: false,
  loading: false,
  error: undefined,
}

const {
  CACHE_ALL_BEGIN,
  CACHE_ALL_SUCCESS,
  CACHE_ALL_FAILURE,
} = ActionTypes

export default (state: InsuranceTypesState = initialState, action: InsuranceTypesAction): InsuranceTypesState => {
  switch (action.type) {
    case CACHE_ALL_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_ALL_SUCCESS:
      return {
        ...state,
        cached: true,
        loading: false,
        data: action.payload.insuranceTypes,
      }

    case CACHE_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
