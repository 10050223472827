import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'matchmedia-polyfill'
import smoothscroll from 'smoothscroll-polyfill'

import React from 'react'
import { loadableReady } from '@loadable/component'
import ReactDOM from 'react-dom'
import { createBroswerContext } from 'use-sse'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import initSentry from './utils/sentry/init'

import {
  persistor,
  store,
} from './state/configureStore'

import './index.css'

import Root from './root'

smoothscroll.polyfill()

initSentry(Sentry)

const BroswerDataContext = createBroswerContext()

const ClientApp: React.FC = (): React.ReactElement => (
  <Provider store={store}>
    <PersistGate loading={undefined} persistor={persistor}>
      <BrowserRouter>
        <BroswerDataContext>
          <Root />
        </BroswerDataContext>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

loadableReady(() => ReactDOM.hydrate(<ClientApp />, document.getElementById('root')))

if (module.hot) {
  module.hot.accept()
}
