export enum ActionTypes {
  RESET_APP = 'app/RESET',
  SET_IS_MAINTENANCE_MODE = 'app/SET_IS_MAINTENANCE_MODE',
  SET_IS_REDUX_LOGGER_ENABLED = 'app/SET_IS_REDUX_LOGGER_ENABLED',
  SET_QUOTING_WRAPPER_BG_IMAGE_INDEX = 'app/SET_QUOTING_WRAPPER_BG_IMAGE_INDEX',
}

export interface AppState {
  isMaintenanceMode?: boolean,
  isReduxLoggerEnabled?: boolean,
  quotingWrapperBgImageIndex?: number,
}

export interface AppAction {
  type: ActionTypes,
  payload: {
    isMaintenanceMode?: boolean,
    isReduxLoggerEnabled?: boolean,
    quotingWrapperBgImageIndex?: number,
  },
}
