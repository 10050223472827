import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  FoundationTypesState,
} from 'state/attributes/home/foundationTypes/types'

const {
  CACHE_FOUNDATION_BEGIN,
  CACHE_FOUNDATION_SUCCESS,
  CACHE_FOUNDATION_FAILURE,
} = ActionTypes

export default (state: FoundationTypesState = deepClone(genericInitialState), action: AttributesAction): FoundationTypesState => {
  switch (action.type) {
    case CACHE_FOUNDATION_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_FOUNDATION_SUCCESS:
      return {
        data: action.payload.foundationTypes,
        cached: true,
        loading: false,
      }

    case CACHE_FOUNDATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
