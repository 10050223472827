import deepClone from 'utils/deepClone'

import { genericInitialState } from 'state/types'

import {
  ActionTypes,
  AttributesAction,
  ReasonsForLapseState,
} from 'state/attributes/reasonsForLapse/types'

const {
  CACHE_REASONS_FOR_LAPSE_BEGIN,
  CACHE_REASONS_FOR_LAPSE_SUCCESS,
  CACHE_REASONS_FOR_LAPSE_FAILURE,
} = ActionTypes

export default (state: ReasonsForLapseState = deepClone(genericInitialState), action: AttributesAction): ReasonsForLapseState => {
  switch (action.type) {
    case CACHE_REASONS_FOR_LAPSE_BEGIN:
      return {
        ...state,
        cached: false,
        loading: true,
      }

    case CACHE_REASONS_FOR_LAPSE_SUCCESS:
      return {
        data: action.payload.reasonsForLapse,
        cached: true,
        loading: false,
      }

    case CACHE_REASONS_FOR_LAPSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
