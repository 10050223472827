const deepClone = (obj: object): object => {
  if (!isObject(obj)) {
    return obj
  }
  const isArray = Array.isArray(obj)
  const cloneObj = isArray ? [] : {}
  for (const key in obj) {
    cloneObj[key] = (obj[key] instanceof Date && obj[key]) || (isObject(obj[key]) && deepClone(obj[key])) || obj[key]
  }

  return cloneObj
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObject = (o: any): boolean => {
  return (typeof o === 'object' || typeof o === 'function') && o !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (obj: any): any => deepClone(obj)
