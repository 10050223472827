import isDebug from 'utils/isDebug'

import {
  ActionTypes,
  CoverageLevelsAction,
  CoverageLevelsState,
  Status,
} from './types'


const {
  CACHE_BEGIN,
  CACHE_SUCCESS,
  CACHE_FAILURE,
  SET_COVERAGE_LEVELS,
} = ActionTypes

export const initialState: CoverageLevelsState = {
  auto: undefined,
  home: undefined,
  cached: {
    insuranceTypes: {},
    insuranceTypeDotStates: {},
  },
  loading: {
    insuranceTypes: {},
    insuranceTypeDotStates: {},
  },
  error: undefined,
}

export default (state: CoverageLevelsState = initialState, action: CoverageLevelsAction): CoverageLevelsState => {
  switch (action.type) {
    case CACHE_BEGIN:
      return {
        ...state,
        cached: generateToggleObjectValues(state, action, 'cached', false),
        loading: generateToggleObjectValues(state, action, 'loading', true),
      }

    case CACHE_SUCCESS: {
      let coverageLevels = {}

      if (action.payload.insuranceType && action.payload.stateAbbr) {
        coverageLevels = {
          ...state,
          [action.payload.insuranceType]: {
            ...(state[action.payload.insuranceType] || {}),
            [action.payload.stateAbbr]: action.payload.coverageLevels[action.payload.insuranceType][action.payload.stateAbbr],
          },
        }
      }
      else if (action.payload.insuranceType && !action.payload.stateAbbr) {
        coverageLevels = {
          ...state,
          [action.payload.insuranceType]: action.payload.coverageLevels[action.payload.insuranceType],
        }
      }

      const ret = {
        ...coverageLevels,
        cached: generateToggleObjectValues(state, action, 'cached', true),
        loading: generateToggleObjectValues(state, action, 'loading', false),
      }

      if (isDebug) {
        console.info('[DEBUG] Coverage Levels state updated', ret) // eslint-disable-line no-console
      }

      return ret
    }

    case CACHE_FAILURE:
      return {
        ...state,
        loading: generateToggleObjectValues(state, action, 'loading', false),
        error: action.payload.error,
      }

    case SET_COVERAGE_LEVELS: {
      let coverageLevels = {}

      if (action.payload.insuranceType && action.payload.stateAbbr) {
        coverageLevels = {
          ...state,
          [action.payload.insuranceType]: {
            ...(state[action.payload.insuranceType] || {}),
            [action.payload.stateAbbr]: action.payload.coverageLevels,
          },
        }
      }
      else if (action.payload.insuranceType && !action.payload.stateAbbr) {
        coverageLevels = {
          ...state,
          [action.payload.insuranceType]: action.payload.coverageLevels,
        }
      }

      const ret = {
        ...state,
        ...coverageLevels,
      }

      return ret
    }

    default:
      return state
  }
}

const generateToggleObjectValues = (state: CoverageLevelsState = initialState, action: CoverageLevelsAction, key: string, value: boolean): Status => {
  let status = { ...state[key] }

  if (action.payload.insuranceType && action.payload.stateAbbr) {
    status = {
      insuranceTypes: state[key].insuranceTypes,
      insuranceTypeDotStates: {
        ...state[key].insuranceTypeDotStates,
        [`${action.payload.insuranceType}.${action.payload.stateAbbr}`]: value,
      },
    }
  }
  else if (action.payload.insuranceType && !action.payload.stateAbbr) {
    status = {
      insuranceTypes: {
        ...state[key].insuranceTypes,
        [action.payload.insuranceType]: value,
      },
      insuranceTypeDotStates: {
        ...Object.keys(state[key].insuranceTypeDotStates).reduce((acc, insuranceTypeDotState: string) => {
          acc[insuranceTypeDotState] = insuranceTypeDotState.match(new RegExp(`/^${action.payload.insuranceType}./`)) ? value : state[key].insuranceTypeDotStates[insuranceTypeDotState]

          return acc
        }, {}),
      },
    }
  }

  return status
}
