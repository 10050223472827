import { createSelector } from 'reselect'

import { RootState } from 'state/types'

import {
  CoverageLevelsForInsuranceType,
  CoverageLevelsForInsuranceTypeAndState,
  CoverageLevelsState,
} from './types'

export const getCoverageLevels = createSelector(
  [ (state: RootState): CoverageLevelsState => state.coverageLevels ],
  coverageLevels => coverageLevels,
)

export const getCoverageLevelsForInsuranceType = createSelector(
  [
    (state: RootState, insuranceType: string): CoverageLevelsForInsuranceType => ({
      cached: state.coverageLevels.cached.insuranceTypes[insuranceType] || false,
      data: (state.coverageLevels.cached && state.coverageLevels[insuranceType]) || undefined,
      error: state.coverageLevels.error,
      loading: state.coverageLevels.loading.insuranceTypes[insuranceType] || false,
    }),
  ],
  coverageLevels => coverageLevels,
)

export const getCoverageLevelsForInsuranceTypeAndState = createSelector(
  [
    (state: RootState, insuranceType: string, stateAbbr: string): CoverageLevelsForInsuranceTypeAndState => ({
      cached: state.coverageLevels.cached.insuranceTypeDotStates[`${insuranceType}.${stateAbbr}`] || false,
      data: (state.coverageLevels.cached && state.coverageLevels[insuranceType]?.[stateAbbr]) || undefined,
      error: state.coverageLevels.error,
      loading: state.coverageLevels.loading.insuranceTypeDotStates[`${insuranceType}.${stateAbbr}`] || false,
    }),
  ],
  coverageLevels => coverageLevels,
)
