import { GenericState } from 'state/types'

export enum ActionTypes {
  CACHE_MAKES_BEGIN = 'vehicle-attributes/CACHE_MAKES_BEGIN',
  CACHE_MAKES_SUCCESS = 'vehicle-attributes/CACHE_MAKES_SUCCESS',
  CACHE_MAKES_FAILURE = 'vehicle-attributes/CACHE_MAKES_FAILURE',
}

export interface VehicleMakes { [key: string]: string }

export interface VehicleMakesForYear extends GenericState {
  data?: VehicleMakes,
}

export interface VehicleMakesState {
  [key: number]: VehicleMakesForYear,
}

export interface CacheVehicleMakesBeginAction {
  type: ActionTypes.CACHE_MAKES_BEGIN,
  payload: {
    year: string,
  },
}

export interface CacheVehicleMakesSuccessAction {
  type: ActionTypes.CACHE_MAKES_SUCCESS,
  payload: {
    year: string,
    makes: VehicleMakesState,
  },
}

export interface CacheVehicleMakesFailureAction {
  type: ActionTypes.CACHE_MAKES_FAILURE,
  payload: {
    year: string,
    error: Error,
  },
}
